import { atom } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { CONST } from '@common/global';

export const autoRefreshTimeLimitationCheckState = atom<Date>({
  key: RecoilKeys.AUTO_REFRESH_TIME_LIMITATION_CHECK_STATE,
  default: new Date(),
  effects: [
    ({ setSelf }) => {
      const interval = setInterval(() => setSelf(new Date()), CONST.TIME_LIMITATION_CHECK_INTERVAL);
      return () => clearInterval(interval);
    },
  ],
});
