import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { ReactComponent as Logo } from '@common/assets/logo-sorakarabin.svg';
import { useState } from 'react';
import { SelectSpotDialog } from './SelectSpotDialog';

export const OrdersDeliverySpotDetail = () => {
  const spotDetailRefresher = dataDeliveryTo.useSpotDetailRefresher();
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <SelectSpotDialog
        isOpen={isOpen}
        closeCallback={() => {
          setIsOpen(false);
          spotDetailRefresher();
        }}
      />
      <SpotDetail />
    </>
  );
};

const SpotDetail = () => {
  const ordersDeliverySpotDetail = dataDeliveryTo.useOrdersDeliverySpotDetail();

  return (
    <div className="flex w-full h-screen text-primaryContrast  bg-[#EEE]">
      <div className="flex-1 text-center">
        <p className="h-1/6 text-7xl font-bold bg-[#00B050] flex items-center justify-center shadow-[0px_10px_10px_-5px_rgb(0,0,0,0.2)]">
          準備中
        </p>
        <div className="h-5/6 flex flex-col items-center overflow-y-auto gap-6 pt-6">
          {ordersDeliverySpotDetail
            .filter(
              (orderDeliverySpotDetail) =>
                orderDeliverySpotDetail.state === 'credited' ||
                orderDeliverySpotDetail.state === 'assigned' ||
                orderDeliverySpotDetail.state === 'accepted'
            )
            .map((orderDeliverySpotDetail) => (
              <p
                key={orderDeliverySpotDetail.orderId}
                className="text-8xl font-bold font-sans bg-[#00B050] w-3/5 px-8 py-4 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
              >
                {orderDeliverySpotDetail.receiptNumber}
              </p>
            ))}
        </div>
      </div>
      <div className="flex-1 text-center">
        <p className="text-7xl font-bold bg-[#2196f3] h-1/6 flex items-center justify-center shadow-[0px_10px_10px_-5px_rgb(0,0,0,0.2)]">
          配達中
        </p>
        <div className="h-4/6 flex flex-col items-center overflow-y-auto gap-6 pt-6">
          {ordersDeliverySpotDetail
            .filter(
              (orderDeliverySpotDetail) =>
                orderDeliverySpotDetail.state === 'onDelivery' ||
                orderDeliverySpotDetail.state === 'arrived'
            )
            .map((orderDeliverySpotDetail) => (
              <p
                key={orderDeliverySpotDetail.orderId}
                className="text-8xl font-bold font-sans bg-[#2196f3] w-3/5 px-8 py-4 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
              >
                {orderDeliverySpotDetail.receiptNumber}
              </p>
            ))}
        </div>
      </div>
      <div className="flex-1 text-center">
        <p className="text-7xl font-bold bg-[#fbaa0d]  h-1/6 flex items-center justify-center shadow-[0px_10px_10px_-5px_rgb(0,0,0,0.2)]">
          お受取可能
        </p>
        <div className="h-4/6 flex flex-col items-center overflow-y-auto gap-6 pt-6">
          {ordersDeliverySpotDetail
            .filter((orderDeliverySpotDetail) => orderDeliverySpotDetail.state === 'posted')
            .map((orderDeliverySpotDetail) => (
              <p
                key={orderDeliverySpotDetail.orderId}
                className="text-8xl font-bold font-sans bg-[#fbaa0d] w-3/5 px-8 py-4 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
              >
                {orderDeliverySpotDetail.receiptNumber}
              </p>
            ))}
        </div>
      </div>

      <div className="text-[#2196f3] fixed bottom-2 right-2">
        <Logo fill="currentColor" className="h-32" />
      </div>
    </div>
  );
};
