import React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { api } from '@common/data/Api';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import { Auth } from 'aws-amplify';
import { DialogOk } from './Dialog';

type MFAProps = {
  readonly mfaState: string;
  readonly setNone: () => void;
  readonly setSMSLocal: () => void;
  readonly setSMS: () => void;
};

export const Account = () => {
  //none, SMS_MFA, SOFTWARE_TOKEN_MFA, SMS_MFA_LOCAL(永続化前の状態)
  const [mfaState, setMfaState] = React.useState<string>('none');

  const setMFANone = () => {
    api.SetMFA('none');
    setMfaState('none');
  };
  const setMFASMSLocal = () => {
    setMfaState('SMS_MFA_LOCAL');
  };
  const setMFASMS = () => {
    setMfaState('SMS_MFA');
  };

  const refFirstRef = React.useRef<boolean>(true);
  React.useEffect(() => {
    // 開発モードの2度呼び防止
    if (process.env.NODE_ENV === 'development') {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        return;
      }
    }
    api.GetMFA().then((res) => {
      console.log(`GetMFA : ${res.PreferredMfaSetting}`);
      setMfaState(res.PreferredMfaSetting);
    });
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <Account2
        mfaState={mfaState}
        setNone={setMFANone}
        setSMSLocal={setMFASMSLocal}
        setSMS={setMFASMS}
      />
    </React.Suspense>
  );
};

export const Account2 = ({ mfaState, setNone, setSMSLocal, setSMS }: MFAProps) => {
  const handleMFAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    if (event.target.checked) {
      setSMSLocal();
    } else {
      setNone();
    }
  };

  return (
    <Stack sx={{ marginY: 2 }} spacing={2} alignItems={'center'}>
      <Typography variant="h5" className="text-center">
        二段階認証設定
      </Typography>
      <Paper sx={{ width: 'auto', padding: 2 }}>
        <div>
          <Typography>
            ・ セキュリティーのため、パスワード認証後の２段階目の認証方式を設定しますか？
          </Typography>
          <div className="mt-2 text-center">
            <FormControlLabel
              checked={mfaState !== 'none'}
              control={<Checkbox onChange={handleMFAChange} />}
              label="設定する"
            />
          </div>
        </div>

        {mfaState === 'SMS_MFA_LOCAL' && <SMSSetup setSMS={setSMS} />}
        {mfaState === 'SMS_MFA' && (
          <p className="text-center text-lg">二段階認証の設定は完了しています</p>
        )}
      </Paper>
    </Stack>
  );
};

const SMSSetup = ({ setSMS }: { setSMS: () => void }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');

  type FormType = {
    code: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      code: '',
    },
  });

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      setProcessing(true);
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', data.code);
      await api.SetMFA('sms');
      setProcessing(false);
      setSMS();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      if (error.code) {
        if (error.code === 'ExpiredCodeException' || error.code === 'CodeMismatchException') {
          setMessage('認証コードが正しくありません');
        } else {
          setMessage('想定外のエラーです');
        }
      }
      console.error(error);
      setOpen(true);
    }
    return;
  };

  return (
    <>
      <DialogOk open={open} callbackOk={() => setOpen(false)} title="エラー" message={message} />
      <div className="mt-4">
        <p className="text-center text-lg">※二段階認証の設定は完了していません</p>
      </div>
      <div className="mt-4">
        <Typography>・ 登録済みの携帯電話番号に認証コードを送信してください</Typography>
        <div className="mt-2 flex justify-center">
          <Button
            variant="contained"
            disabled={processing}
            onClick={async () => {
              try {
                setProcessing(true);
                await Auth.verifyCurrentUserAttribute('phone_number');
                setProcessing(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: Error | any) {
                setProcessing(false);
                if (error.code) {
                  if (error.code === 'LimitExceededException') {
                    setMessage('しばらく時間をおいてから再度お試しください');
                  } else {
                    setMessage('想定外のエラーです');
                  }
                }
                console.error(error);
                setOpen(true);
              }
            }}
          >
            <span>送信</span>
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <Typography>・ 送信された認証コードを入力の上、確定ボタンをクリックしてください</Typography>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center gap-4 mx-auto my-2"
      >
        <Controller
          name="code"
          control={control}
          rules={{
            required: { value: true, message: '入力必須項目です' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="認証コード"
              variant="standard"
              size="small"
              className="w-4/5"
              inputProps={{ maxLength: 6 }}
              error={Boolean(error?.message)}
              helperText={error?.message}
              {...field}
            />
          )}
        />
        <div className="mt-4">
          <Button variant="contained" type="submit" disabled={processing}>
            確定
          </Button>
        </div>
      </form>
    </>
  );
};
