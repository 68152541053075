import { dataShopItemMaster } from './ShopItemMaster';
import { dataShopItemCategory } from './ShopItemCategory';
import { dataShopItemOffer } from './ShopItemOffer';
import { dataShopItemMaker } from './ShopItemMaker';
import { dataShopItemStock } from './ShopItemStock';

export const dataShopItem = {
  itemMaster: dataShopItemMaster,
  category: dataShopItemCategory,
  offer: dataShopItemOffer,
  maker: dataShopItemMaker,
  stock: dataShopItemStock,
};
