import { Auth } from 'aws-amplify';
import { atom, useRecoilValue, useRecoilCallback, selector, useRecoilValueLoadable } from 'recoil';
import { api } from './Api';
import { RecoilKeys } from './RecoilKeys';

// type SIGN_OUT_FUNC = () => void;
// const signOutFuncState = atom<SIGN_OUT_FUNC>({
//   key: RecoilKeys.USER_SIGN_OUT_FUNC_STATE,
// });

// const useSetSignOut = () =>
//   useRecoilCallback(({ set }) => (func: SIGN_OUT_FUNC) => {
//     set(signOutFuncState, () => func)
//   }, [])

// const dataUser = {
// useSignOut: () => useRecoilValue(signOutFuncState),
// useSetSignOut: useSetSignOut,
// };

export type User = {
  userId: string;
  email: string;
  surName: string;
  givenName: string;
  gender: string;
  age: string;
  postalCode: string;
  address: string;
  phone: string;
  phoneNumberVerified: boolean;
  termsOfUse: string;
  privacyPolicy: string;
  rejectEmail?: boolean;
  // MEMO: order_appで使用する
  place?: string;
};

type CognitoUserInfo = {
  emailVerified: boolean;
  phoneNumber: string;
  groups: string[];
};

const userState = atom<User>({
  key: RecoilKeys.USER_STATE,
  effects: [
    ({ setSelf, onSet, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const user = await Auth.currentAuthenticatedUser();
          const data = await api.fetchUser();
          if (data.rejectEmail === undefined) {
            data.rejectEmail = false;
          }
          data.userId = user.username;
          data.email = user.attributes.email;
          if (user.attributes.phone_number && !data.phone) {
            // MEMO: 携帯電話番号がDBに設定されていない場合、cognitoの携帯電話番号を設定
            const phoneNumber: string = user.attributes.phone_number;
            data.phone = '0' + phoneNumber.replace('+81', '');
          }
          setSelf(data);
        };
        initialize();
      }
    },
  ],
});

const isLogin = atom<boolean>({
  key: RecoilKeys.IS_LOGIN,
  effects: [
    ({ setSelf, onSet, trigger }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          try {
            const user = await Auth.currentAuthenticatedUser();
            if (!user) {
              setSelf(false);
              return;
            }
            setSelf(true);
          } catch (e) {
            setSelf(false);
          }
        };
        initialize();
      }
    },
  ],
});

const cognitoUserInfo = selector<CognitoUserInfo>({
  key: RecoilKeys.COGNITO_USER_INFO,
  get: async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      return {
        groups: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'] ?? [],
        phoneNumber: cognitoUser.attributes.phone_number,
        emailVerified: cognitoUser.attributes.email_verified,
      };
    } catch {
      return {
        groups: [],
        phoneNumber: '',
        emailVerified: false,
      };
    }
  },
});

const useStoreUser = () =>
  useRecoilCallback(
    ({ set }) =>
      async (user: User) => {
        await api.postUser(user);
        set(userState, user);
      },
    []
  );

const useStorePhone = async (phone: string) => {
  // cognitoの携帯電話番号も更新
  const loginUser = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(loginUser, {
    phone_number: '+81' + phone.replace('0', ''),
  });
};

const useStorePassword = (oldPassword: string, newPassword: string) => {
  return Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword)
      .then((data) => {
        console.log('password change success');
        return data;
      })
      .catch((err) => {
        console.error(err.message);
        throw new Error(err.message);
      });
  });
};

export const userActions = {
  useGetUser: () => useRecoilValue(userState),
  useGetUserLoadable: () => useRecoilValueLoadable(userState),
  useCognitoUserInfo: () => useRecoilValue(cognitoUserInfo),
  useStoreUser: useStoreUser,
  useStorePhone: () => useStorePhone,
  useStorePassword: useStorePassword,
  useIsLogin: () => useRecoilValue(isLogin),
  useSetLogin: () =>
    useRecoilCallback(
      ({ set }) =>
        () =>
          set(isLogin, true)
    ),
};
