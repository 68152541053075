import { selector, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';

export type Coupons = {
  itemId: string;
  startTime: string;
  expiredTime: string;
  overview: string;
};

export const couponsState = selector<Coupons[]>({
  key: RecoilKeys.COUPONS_STATE,
  get: ({ get }) => {
    return api.fetchCoupons();
  },
});

export const dataCoupons = {
  useCoupons: () => useRecoilValue(couponsState),
  useCouponsLoadable: () => useRecoilValueLoadable(couponsState),
};
