/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { CONST } from '../global';
import { DeliveryTimeOption } from './Order';
import NoImage from '@common/assets/NoImage.svg';
import moment from 'moment';
import { User } from './User';
import { usePlaceOrderSkippingPayment, useGotoPayment, useCancelRequest } from './ApiOrder';

axios.interceptors.request.use(
  async (config) => {
    config.url = CONST.API_ENDPOINT + config.url;
    config.headers['X-Sorakarabin'] = 'sorakarabin';
    try {
      const user = await Auth.currentAuthenticatedUser();
      config.headers['Authorization'] = user.signInUserSession.idToken.jwtToken;
      return config;
    } catch (err) {
      return config;
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    console.log('API response:', res);
    return res;
  },
  (err) => {
    console.log('API error');
    console.log('Config', err.config);
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Request', err.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error message', err.message);
    }
    return Promise.reject(err);
  }
);

const fetchUser = async (): Promise<User> => {
  return axios
    .get(`v1/users`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return {};
    });
};

const postUser = async (user: User) => {
  return axios
    .post('v1/users/upsert', user)
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      console.error(err);
      return 500;
    });
};

const fetchCart = async () => {
  return axios
    .get(`v1/carts`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return {};
    });
};

const postCart = async (cart: {
  items: string;
  deliveryDate: Date;
  shopId: string;
  spotId: string;
}) => {
  return axios
    .post('v1/carts/upsert', cart)
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      console.error(err);
      return 500;
    });
};

export type ApiShop = {
  allowablePostcodes: string[];
  shopId: string;
  shopName: string;
  image: string[];
  spotId: string[];
  latitude?: string;
  longitude?: string;
};
const fetchShops = async (): Promise<ApiShop[]> => {
  return axios
    .get(`v1/shops`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return [];
    });
};

export type ApiOrdersDeliverySpotDetail = {
  orderId: string;
  receiptNumber: number;
  state: 'credited' | 'assigned' | 'accepted' | 'onDelivery' | 'arrived' | 'posted';
};
const fetchOrdersDeliverySpotId = async (
  deliverySpotId: string
): Promise<ApiOrdersDeliverySpotDetail[]> => {
  return axios
    .get(`v1/orders/deliverySpotId/${deliverySpotId}`)
    .then((res) => {
      console.log(res.data.message);
      const data = res.data.message;
      return data;
    })
    .catch((err) => {
      return [];
    });
};

const fetchItems = async (shopId: string) => {
  return (
    axios
      //ex .get(v1/items/?shopId=1&maxItemSize=4)
      .get(`v1/items/?shopId=${shopId}&maxItemSize=${CONST.MAX_CART_SIZE}`)
      .then((res) => {
        return res.data.message
          .map((x: any) => ({
            ...x,
            image: x.image && x.image.length > 0 ? x.image : [NoImage],
            priority: x.priority ? Number(x.priority) : 0,
            price: x.price ? Number(x.price) : 0,
            size: x.size ? Number(x.size) : CONST.DEFAULT_ITEM_SIZE,
            weight: x.weight ? Number(x.weight) : CONST.DEFAULT_ITEM_WEIGHT,
          }))
          .sort((a: any, b: any) => b.priority - a.priority);
      })
      .catch((err) => {
        return [];
      })
  );
};

const fetchItem = async (itemId: string) => {
  return axios
    .get(`v1/items/itemId/${itemId}`)
    .then((res) => {
      let data = res.data.message;
      return (data = {
        ...data,
        image: data.image && data.image.length > 0 ? data.image : [NoImage],
        priority: data.priority ? Number(data.priority) : 0,
        price: data.price ? Number(data.price) : 0,
        size: data.size ? Number(data.size) : CONST.DEFAULT_ITEM_SIZE,
        weight: data.weight ? Number(data.weight) : CONST.DEFAULT_ITEM_WEIGHT,
      });
    })
    .catch((err) => {
      return [];
    });
};

const fetchCategories = async (shopId: string) => {
  return axios
    .get(`v1/categories/shopId/${shopId}`)
    .then((res) => {
      return res.data.message
        .map((x: any) => ({ ...x, priority: x.priority ? Number(x.priority) : 0 }))
        .sort((a: any, b: any) => b.priority - a.priority);
    })
    .catch((err) => {
      return [];
    });
};

const fetchOffers = async (shopId: string) => {
  return axios
    .get(`v1/offers/shopId/${shopId}`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return [];
    });
};

const fetchStocks = async (shopId: string) => {
  return axios
    .get(`v1/stocks/shopId/${shopId}`)
    .then((res) => {
      return res.data.message.map((x: any) => ({
        itemId: x.itemId,
        stockCount: Number(x.freeStockCount),
      }));
    })
    .catch((err) => {
      return [];
    });
};

const fetchStock = async (shopId: string, itemId: string) => {
  return axios
    .get(`v1/stocks/getStock/${shopId}/${itemId}`)
    .then((res) => {
      const data = res.data.message;
      return {
        itemId: String(data.itemId),
        stockCount: Number(data.freeStockCount),
      };
    })
    .catch((err) => {
      return null;
    });
};

const fetchMakers = async () => {
  return axios
    .get('v1/makers')
    .then((res) => {
      return res.data.message.map((x: any) => ({
        ...x,
        image: x.image ? x.image : [''],
      }));
    })
    .catch((err) => {
      return [];
    });
};

const fetchDeliveryTimeOptions = async (spotId: string) => {
  return axios
    .get(`v1/capacity/spotId/${spotId}`)
    .then((res) => {
      return res.data.map((x: any) => {
        const begin = moment.unix(x['deliveryTimeBegin']).utcOffset(+9);
        const end = begin.clone().add(CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES, 'minute');
        return new DeliveryTimeOption(
          begin.year(),
          begin.month(),
          begin.date(),
          begin.hours(),
          begin.minute(),
          end.hours(),
          end.minute(),
          x['free']
        );
      });
    })
    .catch((err) => {
      return [];
    });
};

const fetchIsOperatingDay = async (spotId: string, date = '') => {
  return axios
    .get(`v1/capacity/spotId/${spotId}/operatingDay`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return false;
    });
};

export type APIOperatingDaysResponse = Record<string, boolean>; //spotId, true or false

const fetchIsOperatingDayShopId = async (shopId: string, date = '') => {
  return axios
    .get(`v1/capacity/shopId/${shopId}/operatingDay`)
    .then((res) => {
      return res.data as APIOperatingDaysResponse;
    })
    .catch((err) => {
      return {} as APIOperatingDaysResponse;
    });
};

export type APIDeliveryAvailabilityShopId = Record<string, boolean>; //spotId, true or false

const fetchDeliveryAvailabilityShopId = async (shopId: string, date = '') => {
  return axios
    .get(`v1/capacity/shopId/${shopId}/deliveryAvailability`)
    .then((res) => {
      return res.data as APIDeliveryAvailabilityShopId;
    })
    .catch((err) => {
      return {} as APIDeliveryAvailabilityShopId;
    });
};

const fetchAgreements = async () => {
  return axios
    .get('v1/agreements/get')
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return [];
    });
};

const fetchAppMessages = async (shopId: string) => {
  return axios
    .get(`v1/app-msg/shopId/${shopId}`)
    .then((res) => {
      return res.data.message.map((x: any) => ({ ...x }));
    })
    .catch((err) => {
      return [];
    });
};

export type ApiSpot = {
  spotId: string;
  spotName: string;
  latitude: number;
  longitude: number;
  image: string[];
};

const fetchSpots = async (): Promise<ApiSpot[]> => {
  return axios
    .get('v1/spots')
    .then((res) => {
      console.log('fetchSpots');
      console.log(res);
      return res.data.message.map(
        (x: ApiSpot): ApiSpot => ({
          ...x,
          latitude: Number(x.latitude),
          longitude: Number(x.longitude),
        })
      );
    })
    .catch((err) => {
      return [];
    });
};

const fetchCoupons = async () => {
  return axios
    .get('v1/coupons')
    .then((res) => {
      console.log(res.data.message);
      // const data = res.data.message;
      // return data;
      return [];
    })
    .catch((err) => {
      return [];
    });
};

export type ApiOrderItem = {
  itemId: string;
  count: number;
  price: number;
  name: string;
  maker: string;
  image: string;
};

export type ApiOrderItems = {
  storeId: number;
  items: ApiOrderItem[];
  price: number;
  deliveryFee: number;
  deliveryTimeBegin: number;
  deliveryTimeEnd: number;
  deliveryTo: string;
};

export type ApiOrder = {
  orderId: string;
  state: string;
  storeId: number;
  items: ApiOrderItem[];
  price: number;
  deliveryFee: number;
  deliveryFeeId: string;
  discount: number;
  discountId: string;
  deliveryTimeBegin: number;
  deliveryTimeEnd: number;
  deliveryTo: string;
  deliverySpotId: string;
  lastModified: number;

  //   timePreOrder: number;
  timePaying: number;
  timeCredited?: number;
  timeAccepted?: number;
  timeOnDelivery?: number;
  timeArrived?: number;
  timePosted?: number;
  timeCompleted?: number;
  timePayingTimeout?: number;
  timeFailed?: number;
  timeCancelled?: number;

  boxId?: string;
  cancelCause?: string;
  stockOutItems?: ApiOrderItem[];

  receiptNumber?: string;
  boxKey?: string;

  payMethod?: string;
  cancellationReason?: string;
  phoneNumber?: string;
};

async function PostOrderCart(orderItems: ApiOrderItems): Promise<ApiOrder> {
  const postData = JSON.stringify(orderItems);
  // console.log('PostOrderCart')
  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/orders', postData);
  console.log(`PostOrderCart : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  return data;
}

async function ListOrders(lastModified = 0): Promise<ApiOrder[]> {
  // console.log('ListOrders')
  const updateTime = Date.now();
  const { status, data } = await axios.get(`v1/orders/${lastModified}`);
  console.log(`ListOrders : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  return data;
}

async function ChangeDeliveryTime({
  orderId,
  DeliveryTimeBegin,
  DeliveryTimeEnd,
}: {
  orderId: string;
  DeliveryTimeBegin: number;
  DeliveryTimeEnd: number;
}) {
  const { data } = await axios.post('v1/orders/change_deliveryTime', {
    orderId,
    DeliveryTimeBegin,
    DeliveryTimeEnd,
  });

  if (data.status === 'failed') {
    throw new Error(data.reason);
  }
}

type ApiGetMFA = {
  message: string;
  PreferredMfaSetting: string;
};
async function GetMFA(): Promise<ApiGetMFA> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/getMFA', postData, config);
  console.log(`getMFA : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

async function SetMFA(mfaMethod: string): Promise<ApiGetMFA> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
    mfa: mfaMethod,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/setMFA', postData, config);
  console.log(`setMFA : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

type ApiAssociateSoftwareToken = {
  message: string;
  totpCode: string;
  secretCode: string;
};
async function AssociateSoftwareToken(): Promise<ApiAssociateSoftwareToken> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/associateSoftwareToken', postData, config);
  console.log(`associateSoftwareToken : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

async function VerifySoftwareToken(totp: string): Promise<ApiGetMFA> {
  const user = await Auth.currentAuthenticatedUser();
  const idToken = user.signInUserSession.idToken.jwtToken;
  const config = { headers: { Authorization: idToken } };

  const accessToken = user.signInUserSession.accessToken.jwtToken;

  const postData = {
    accessToken: accessToken,
    userCode: totp,
  };

  const updateTime = Date.now();
  const { status, data } = await axios.post('v1/security/verifySoftwareToken', postData, config);
  console.log(`verifySoftwareToken : ${status} - ${(0.0 + Date.now() - updateTime) / 1000}[s]`);
  console.log(data);
  return data;
}

export const api = {
  fetchUser,
  postUser,
  fetchCart,
  postCart,
  fetchShops,
  fetchItems,
  fetchItem,
  fetchCategories,
  fetchCoupons,
  fetchOffers,
  fetchSpots,
  fetchOrdersDeliverySpotId,
  fetchStocks,
  fetchStock,
  fetchMakers,
  fetchDeliveryTimeOptions,
  fetchIsOperatingDay,
  fetchIsOperatingDayShopId,
  fetchDeliveryAvailabilityShopId,
  PostOrderCart,
  ListOrders,
  ChangeDeliveryTime,
  fetchAgreements,
  fetchAppMessages,
  GetMFA,
  SetMFA,
  AssociateSoftwareToken,
  VerifySoftwareToken,

  usePlaceOrderSkippingPayment,
  useGotoPayment,
  useCancelRequest,
};
