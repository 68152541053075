// import React from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import moment from 'moment';
// // MUI setup
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material/styles';

// Local components
import { theme } from './theme';
import { Shop } from './Shop';
import { OrderConfirm } from './OrderConfirm';
import { OrderCompleted } from './OrderCompleted';
import { OrderFailed } from './OrderFailed';
import { OrderCanceled } from './OrderCanceled';
import { OrderClear } from './OrderClear';
import { Menu as MyMenu } from '../menu/Menu';
import { Agreement } from './agreement/Agreement';

// import useLocationChange from '@common/hooks/UseLocationChange';
// import { Tracking } from '@common/utils/Tracking';

import coverImg from '@common/assets/cover_01.png';

// Amplify
import { Amplify, I18n } from 'aws-amplify';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import { dataShopItem } from '@common/data/ShopItem';
import { Suspense, useEffect, useState } from 'react';
import { useClickActionButtonSound, useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { CircularProgress } from '@mui/material';
import { UserInfo } from './UserInfo';
import { TimeoutChecker } from './TimeoutChecker';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataCoupons } from '@common/data/OrderCoupons';
import { OrdersDeliverySpotDetail } from './OrdersDeliverySpotDetail';
import { OrderCart } from './OrderCart';
import { PhoneNumberForm } from './PhoneNumberForm';
import { dataOrder } from '@common/data/Order';
import { ShopSelect } from './ShopSelect';
import { dataShop } from '@common/data/Shop';
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { useOnline } from '@common/hooks/UseOnline';

Amplify.configure(awsExports);
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

export const App = () => {
  if (!process.env.REACT_APP_API_ENDPOINT) {
    console.error('REACT_APP_API_ENDPOINTが設定されていません.');
  }

  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Authenticator>
          {({ signOut, user }) => (
            <Suspense
              fallback={
                <div className="w-full h-screen flex justify-center items-center">
                  <CircularProgress />
                </div>
              }
            >
              <App2 />
            </Suspense>
          )}
        </Authenticator>
      </RecoilRoot>
    </ThemeProvider>
  );
};

const App2 = () => {
  const [online, setOnline] = useState(navigator.onLine);

  // MEMO: オンライン状態変更時に走る処理
  useOnline(
    () => {
      setOnline(true);
    },
    () => {
      setOnline(false);
    }
  );
  return (
    <>
      <BrowserRouter>
        {online ? (
          <Routes>
            <Route index element={<TopPage />} />
            <Route path="*" element={<TopPage />} />
            <Route path="shop">
              <Route index element={<Shop />} />
              <Route path="categories/:categoryId" element={<Shop />} />
            </Route>
            <Route path="order">
              <Route
                path="cart"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen flex justify-center items-center">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <OrderCart />
                  </Suspense>
                }
              />
              <Route path="phoneNumber" element={<PhoneNumberForm />} />
              <Route
                path="confirm"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen flex justify-center items-center">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <OrderConfirm />
                  </Suspense>
                }
              />
              <Route
                path="completed/:orderId"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen flex justify-center items-center">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <OrderCompleted />
                  </Suspense>
                }
              />
              <Route path="failed/:orderId" element={<OrderFailed />} />
              <Route path="canceled/:orderId" element={<OrderCanceled />} />
              <Route
                path="timeout"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen flex justify-center items-center">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <OrderClear />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="ordersDeliverySpotDetail"
              element={
                <Suspense fallback={<CircularProgress />}>
                  <OrdersDeliverySpotDetail />
                </Suspense>
              }
            />
            <Route path="account" element={<UserInfo />} />
            <Route
              path="shopSelect"
              element={
                <Suspense
                  fallback={
                    <div className="w-full h-screen flex justify-center items-center">
                      <CircularProgress />
                    </div>
                  }
                >
                  <ShopSelect />
                </Suspense>
              }
            />
            <Route path="AdministratorMenu" element={<MyMenu />} />
            <Route path="agreement" element={<Agreement />} />
          </Routes>
        ) : (
          <div className="mt-2">
            <p className="text-center text-xl">通信中にエラーが発生しました。</p>
            <p className="text-center text-xl">通信環境をご確認の上、もう一度お試しください。</p>
          </div>
        )}
      </BrowserRouter>
    </>
  );
};

const TopPage = () => {
  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();
  // NOTE: TOPページで必要なデータをフェッチしておく
  dataShopItem.category.useCategoriesLoadable();
  dataShopItem.itemMaster.useAllShopItemsLoadable();
  dataShopItem.maker.useAllShopItemMakerLoadable();
  const selectedShop = dataShop.useShopSelected();
  dataShopItem.stock.useShopItemStockLoadable(selectedShop?.shopId);
  dataDeliveryTo.useDeliveryToLoadable();
  dataCoupons.useCouponsLoadable();
  useClickActionButtonSound();
  const categoryRefresh = dataShopItem.category.useCategoryRefresher();
  const shopItemRefresh = dataShopItem.itemMaster.useShopItemRefresher();
  const shopItemMakerRefresh = dataShopItem.maker.useShopItemMakerRefresher();
  const shopItemStockRefresh = dataShopItem.stock.useShopItemStockRefresher();
  const setOrderPhoneNumber = dataOrder.phoneNumber.useSetOrderPhoneNumber();
  const firstDeliveryTimeOption = dataOrder.deliveryTime.useDeliveryFirstAvailableTimeOption();
  // 在庫は特定時間ごとに更新
  dataShopItemStock.useShopItemStockRefreshInterval();
  dataOrder.deliveryTime.useDeliveryTimeOptionRefreshInterval();
  dataOrder.deliveryTo.useUpdateSpotSelectedIdInterval();

  useEffect(() => {
    // キャッシュをリフレッシュ
    categoryRefresh();
    shopItemRefresh();
    shopItemMakerRefresh();
    shopItemStockRefresh();
    setOrderPhoneNumber('');
  }, []);

  function navigateShop() {
    TimeoutChecker();
    playClickNavigationButtonSound();
    navigate('/shop');
  }

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="w-full h-4/5">
        <img
          alt="cover"
          src={coverImg}
          className="object-fill w-full h-full"
          onClick={navigateShop}
        />
      </div>
      <div className="w-full grow flex items-center justify-center">
        <div
          className="w-1/2 py-3 rounded-full cursor-pointer 
          text-center text-4xl font-bold text-white bg-primary"
          onClick={navigateShop}
        >
          {!firstDeliveryTimeOption ||
          moment(firstDeliveryTimeOption.date).diff(moment().startOf('day'), 'days') !== 0
            ? '本日はお届け可能な時間がありません'
            : '注文する'}
        </div>
      </div>
      <div className="hidden"> test</div>
    </div>
  );
};
