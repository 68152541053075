import { useEffect } from 'react';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { CONST } from '../global';
import { api, ApiOrdersDeliverySpotDetail } from './Api';
import { shopsIncludeSpots, getShops } from './Shop';
import {
  isAnySpotAvailableForDeliveryToday,
  deliveryAvailabilitySpotsStatus,
} from './OrderDeliveryTime';

type Spots = {
  spotId: string;
  spotName: string;
  latitude: number;
  longitude: number;
  image: string[];
};

export const deliveryToState = selector<Spots[]>({
  key: RecoilKeys.DELIVERY_TO_STATE,
  get: ({ get }) => api.fetchSpots(),
});

const REFRESH_TIME = 15 * 1000;
// 特定boxへの注文の状態を取得
const ordersDeliverySpotDetailState = atom<ApiOrdersDeliverySpotDetail[]>({
  key: RecoilKeys.ORDERS_DELIVERY_SPOT_DETAIL_STATE,
  effects: [
    ({ setSelf, getPromise }) => {
      const initialize = async () => {
        const spotId = await getPromise(spotSelectedIdState);
        api.fetchOrdersDeliverySpotId(spotId).then((data) => setSelf(data));
      };

      initialize();
      // MEMO: 15秒ごとにリフェッチ
      setInterval(initialize, REFRESH_TIME);
    },
  ],
});

export const spotSelectedIdDefault = selector({
  key: 'spotSelectedIdDefault',
  get: () => {
    const spotSelected = localStorage.getItem('spotSelectedId') ?? '2';
    return spotSelected;
  },
});

export const spotSelectedIdState = atom<string>({
  key: RecoilKeys.SPOT_SELECTED_ID_STATE,
  default: spotSelectedIdDefault,
  effects: [
    ({ trigger, setSelf, onSet, getPromise }) => {
      // localStorageに値を保持する
      if (trigger === 'get') {
        const spotSelected = localStorage.getItem('spotSelectedId') ?? '2';
        const initialize = async () => {
          const shops = await getPromise(getShops);
          // 前回選択していた配送先がshopにあれば、それを選択
          const shopSelected = shops.find((shop) => {
            return shop.spotId.includes(spotSelected);
          });
          if (shopSelected) {
            const availabilityStatus = await getPromise(
              deliveryAvailabilitySpotsStatus(shopSelected.shopId)
            );
            const isSpotAvailable = availabilityStatus ? availabilityStatus[spotSelected] : false;

            if (isSpotAvailable || !isAnySpotAvailableForDeliveryToday(shopSelected.shopId)) {
              // 選択された配達枠に当日配達可能なとき、もしくはすべてのスポットに配達可能枠がないとき
              console.log(
                '選択された配達枠に当日配達可能なとき、もしくはすべてのスポットに配達可能枠がないとき'
              );
              console.log('spotSelected : ', spotSelected);
              setSelf(spotSelected);
              return;
            } else {
              // 他のスポットに配達可能枠があるならスポットを変更
              const availableSpotIds = availabilityStatus
                ? Object.keys(availabilityStatus).filter((spotId) => availabilityStatus[spotId])
                : [];
              const firstAvailableSpotId =
                availableSpotIds.length > 0 ? availableSpotIds[0] : undefined;
              if (firstAvailableSpotId) {
                console.log('他のスポットに配達可能枠があるならスポットを変更');
                console.log(firstAvailableSpotId);
                setSelf(firstAvailableSpotId);
                return;
              }
            }
          } else {
            // 前回選択していた配送先がshopになければ、虹ヶ丘店の配送先を選択
            setSelf('2');
            localStorage.setItem('spotSelectedId', '2');
          }
        };

        initialize();
      }

      onSet((newValue) => {
        console.log('spotId : ', newValue);
        localStorage.setItem('spotSelectedId', newValue);
      });
    },
  ],
});

// 最適なスポットを自動設定
const useUpdateSpotSelectedId = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const shops = await snapshot.getPromise(getShops);
        const spotSelected = await snapshot.getPromise(spotSelectedIdState);

        // 前回選択していた配送先がshopにあれば、それを選択
        const shopSelected = shops.find((shop) => shop.spotId.includes(spotSelected));

        if (shopSelected) {
          const availabilityStatus = await snapshot.getPromise(
            deliveryAvailabilitySpotsStatus(shopSelected.shopId)
          );
          const isSpotAvailable = availabilityStatus ? availabilityStatus[spotSelected] : false;

          if (isSpotAvailable) {
            // 選択された配達枠に当日配達可能なとき
            console.log('選択された配達枠に当日配達可能');
            // spotSelectedIdStateは既に適切な値であるため、更新不要
          } else {
            if (availabilityStatus) {
              // 他のスポットに配達可能枠があるならスポットを変更
              const availableSpotIds = Object.keys(availabilityStatus).filter(
                (spotId) => availabilityStatus[spotId]
              );
              const firstAvailableSpotId =
                availableSpotIds.length > 0 ? availableSpotIds[0] : undefined;

              if (firstAvailableSpotId) {
                console.log('他のスポットに配達可能枠があるならスポットを変更');
                set(spotSelectedIdState, firstAvailableSpotId);
              } else {
                console.log('どのスポットにも配達可能枠がないので変更せず');
              }
            }
          }
        } else {
          console.log('利用可能なスポットがありません');
        }
      },
    []
  );
};

const useUpdateSpotSelectedIdInterval = () => {
  const refresh = useUpdateSpotSelectedId();
  useEffect(() => {
    const intervalId = setInterval(refresh, CONST.TIME_DELIVERY_TIME_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);
};

const spotSelected = selector<Spots>({
  key: RecoilKeys.SPOT_SELECTED,
  get: async ({ get }) => {
    const spots = get(deliveryToState);
    const selectedSpotId = get(spotSelectedIdState);

    return (
      spots.find((spot) => spot.spotId === selectedSpotId) ?? {
        spotId: 'error',
        spotName: '選択している配送先が不正です。再度選択してください。',
        latitude: 0,
        longitude: 0,
        image: [],
      }
    );
  },
});

export type SpotAndShop = {
  shopId: string;
  shopName: string;
  image: string[];
  latitude: string;
  longitude: string;
  spot: Spots;
};

const spotAndShopBySpotId = selectorFamily<SpotAndShop, string>({
  key: RecoilKeys.SPOT_AND_SHOP_IN_ORDER,
  get:
    (spotId) =>
    async ({ get }) => {
      const shopIncludeSpots = get(shopsIncludeSpots).find((shopIncludeSpot) =>
        shopIncludeSpot.spotId.includes(spotId)
      );
      if (!shopIncludeSpots) {
        return {
          shopId: 'error',
          shopName: '選択している配送先が不正です。再度選択してください。',
          image: [],
          latitude: '',
          longitude: '',
          spot: {
            spotId: 'error',
            spotName: '選択している配送先が不正です。再度選択してください。',
            latitude: 0,
            longitude: 0,
            image: [],
          },
        };
      }

      const spot = shopIncludeSpots.spots.find((spot) => spot.spotId === spotId);
      if (!spot) {
        return {
          shopId: shopIncludeSpots.shopId,
          shopName: shopIncludeSpots.shopName,
          image: shopIncludeSpots.image,
          latitude: shopIncludeSpots.latitude ?? '0',
          longitude: shopIncludeSpots.longitude ?? '0',
          spot: {
            spotId: 'error',
            spotName: '選択している配送先が不正です。再度選択してください。',
            latitude: 0,
            longitude: 0,
            image: [],
          },
        };
      }
      return {
        ...shopIncludeSpots,
        latitude: shopIncludeSpots.latitude ?? '0',
        longitude: shopIncludeSpots.longitude ?? '0',
        spot,
      };
    },
});

const useSpotDetailRefresher = () =>
  useRecoilCallback(({ set, snapshot }) => async () => {
    const spotId = await snapshot.getPromise(spotSelectedIdState);
    set(ordersDeliverySpotDetailState, await api.fetchOrdersDeliverySpotId(spotId));
  });

export const dataDeliveryTo = {
  useDeliveryTo: () => useRecoilValue(deliveryToState),
  useDeliveryToLoadable: () => useRecoilValueLoadable(deliveryToState),
  useOrdersDeliverySpotDetail: () => useRecoilValue(ordersDeliverySpotDetailState),
  useSpotSelectedIdState: () => useRecoilState(spotSelectedIdState),
  useUpdateSpotSelectedId,
  useUpdateSpotSelectedIdInterval: () => useUpdateSpotSelectedIdInterval(),
  useSpotSelected: () => useRecoilValue(spotSelected),
  useSpotAndShopBySpotId: (spotId: string) => useRecoilValue(spotAndShopBySpotId(spotId)),
  useSpotDetailRefresher,
  useSpotRefresher: () => useRecoilRefresher_UNSTABLE(deliveryToState),
};
