import { CONST } from '@common/global';
import { ShopItemCategory } from './ShopItemCategory';

const isPresentTimeOverDeadline = (presentTime: Date, deliveryDate: Date) => {
  // MEMO: Dateはオブジェクトなので参照渡しとなる、deliveryDate の時分は 0 のままの必要があるためコピーを作成
  const cloneDeliveryDate = new Date(
    deliveryDate.getFullYear(),
    deliveryDate.getMonth(),
    deliveryDate.getDate(),
    CONST.TODAYS_LUNCH.DEADLINE_HOUR,
    CONST.TODAYS_LUNCH.DEADLINE_MINUTE
  );
  return presentTime > cloneDeliveryDate;
};

const filterAvailableCategories = (categories: ShopItemCategory[], presentTime: Date, deliveryDate: Date) => {
  return isPresentTimeOverDeadline(presentTime, deliveryDate)
    ? categories.filter((category) => category.categoryId !== CONST.TODAYS_LUNCH.CATEGORY_ID)
    : categories;
};

const isCartItemsAvailable = (categories: string[], presentTime: Date, deliveryDate: Date) => {
  return isPresentTimeOverDeadline(presentTime, deliveryDate)
    ? categories.map((category) => category !== CONST.TODAYS_LUNCH.CATEGORY_ID)
    : categories.map(() => true);
};

export const limitationByTodaysLunch = {
  filterAvailableCategories,
  isCartItemsAvailable,
};
