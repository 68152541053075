import { useRecoilCallback, Snapshot } from 'recoil';
import { cartState, cartTotalPriceSelector } from './OrderCart';
import { spotSelectedIdState } from './OrderDeliveryTo';
import { deliveryTimeState } from './OrderDeliveryTime';
import { shopItemsMasterState } from './ShopItemMaster';
import { deliveryFeeState } from './OrderDeliveryFee';
import { discountState } from './OrderDiscount';
import { couponsState } from './OrderCoupons';
import { BuyRequest, getFormValues, updateFormValues } from './Sbps';
import axios from 'axios';
import { OrderPhoneNumberNoHyphen } from './OrderPhoneNumber';
import { shopSelected } from './Shop';

type PostDataItem = {
  itemId: string;
  count: number;
  // name?: string,
};

type PostData = {
  items: PostDataItem[];
  shopId: string;
  deliverySpotId: string;
  deliveryTimeBegin: number;
  deliveryTimeEnd: number;
  deliveryFee: number;
  deliveryFeeId: string;
  discount: number;
  discountId: string;
  formValue: string;
  phoneNumber: string;
};

const getPostData = async (snapshot: Snapshot, vendingMachine: boolean) => {
  const selectedShop = await snapshot.getPromise(shopSelected);
  const selectedSpotId = await snapshot.getPromise(spotSelectedIdState);
  const cart = await snapshot.getPromise(cartState);
  const phoneNumber = await snapshot.getPromise(OrderPhoneNumberNoHyphen);
  const deliveryTime = await snapshot.getPromise(deliveryTimeState);
  const deliveryFee = await snapshot.getPromise(deliveryFeeState);
  const discount = await snapshot.getPromise(discountState);
  const coupons = await snapshot.getPromise(couponsState);
  const itemMaster = await snapshot.getPromise(shopItemsMasterState);
  const totalPrice = (await snapshot.getPromise(cartTotalPriceSelector)) + deliveryFee + discount;
  const items = cart.items.map((item) => {
    const master = itemMaster.find((x) => x.itemId === item.itemId);
    return { ...item, name: master?.name ?? '', price: master?.price ?? 0 };
  });
  const deliveryFeeItem = { itemId: 'XXXXXXXXXXXX', name: '送料', price: deliveryFee, count: 1 };
  items.push(deliveryFeeItem);

  let discountItemPrice = 0;
  let discountItemId = '';
  if (discount !== 0) {
    for (const coupon of coupons) {
      const master = itemMaster.find((x) => x.itemId === coupon.itemId);
      const discountItem = {
        itemId: coupon.itemId,
        name: master?.name ?? '',
        price: master?.price ?? 0,
        count: 1,
      };
      items.push(discountItem);
      discountItemPrice += discountItem.price;
      discountItemId = discountItem.itemId; // TODO:複数に対応する必要あり
    }
  }
  console.log(items);
  const form = BuyRequest(items, totalPrice, vendingMachine);
  console.log(form);

  const postData: PostData = {
    items: cart.items,
    shopId: selectedShop.shopId,
    deliverySpotId: selectedSpotId,
    deliveryTimeBegin: deliveryTime.getTimeOfBegin() / 1000,
    deliveryTimeEnd: deliveryTime.getTimeOfEnd() / 1000,
    deliveryFee: deliveryFeeItem.price,
    deliveryFeeId: deliveryFeeItem.itemId,
    discount: discountItemPrice,
    discountId: discountItemId,
    formValue: '',
    phoneNumber: phoneNumber,
  };
  console.log(JSON.stringify(postData));
  return { postData: postData, form: form };
};

export const usePlaceOrderSkippingPayment = (vendingMachine = false) => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const ret = await getPostData(snapshot, vendingMachine);

        return axios
          .post('v1/orders/skip_payment', ret.postData)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return undefined;
          });
      },
    []
  );
};

export const useGotoPayment = (vendingMachine = false) => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const ret = await getPostData(snapshot, vendingMachine);
        const formValues = getFormValues(ret.form);

        ret.postData.formValue = formValues;

        return axios
          .post('v1/sbps-msg', ret.postData)
          .then((res) => {
            if (res.data.status === 'ok') {
              console.log(res.data);
              updateFormValues(ret.form, res.data.formValues);
              ret.form?.submit();
            } else {
              return res.data;
            }
          })
          .catch((err) => {
            return undefined;
          });
      },
    []
  );
};

export const useCancelRequest = (orderId: string) => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const postData = {
          orderId: orderId,
        };
        return axios
          .post('v1/orders/cancel_request', postData)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return undefined;
          });
      },
    []
  );
};

let failedMessage: string | undefined;
export const useFetchFailedMessage = (orderId: string): string => {
  // MEMO: ERROR_SBPSについてはコード部が動的なので、下記に定義なし
  const failedReasons: { reason: string; message: string }[] = [
    {
      reason: 'CAPACITY_EXPIRED',
      message: 'ご指定の配達時間が配達直前です\n再度注文を行なってください',
    },
    {
      reason: 'OUT_OF_CAPACITY',
      message: 'ご指定の配達時間が利用不可です\n再度注文を行なってください',
    },
    {
      reason: 'OUT_OF_STOCK',
      message: '購入商品の在庫が無くなりました\n再度注文を行なってください',
    },
    { reason: 'UNKNOWN', message: '不明なエラーです' },
  ];

  if (!failedMessage) {
    throw axios
      .get(`v1/orders/failed/${orderId}`)
      .then((res) => {
        const failedOrder = res.data.message;
        let message = '';
        if (failedOrder.paymentError.includes('ERROR_SBPS')) {
          message = `エラーコード ${failedOrder.paymentError}`;
        }

        // SBPSエラーではない場合は配列からメッセージを抽出
        if (message === '') {
          message =
            failedReasons.find((failedReason) => {
              return failedReason.reason === failedOrder?.paymentError;
            })?.message ?? '不明なエラーです';
        }
        failedMessage = message;
      })
      .catch((err) => {
        console.error('fetch failed order error');
        failedMessage = '不明なエラーです';
      });
  }
  return failedMessage;
};
