import React from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type DialogOkProps = {
  readonly open: boolean;
  readonly title?: string;
  readonly message?: string;
  readonly textOk?: string;
  readonly callbackOk: () => void;
  readonly callbackOnClose?:
    | ((event: object, reason: 'backdropClick' | 'escapeKeyDown') => void)
    | undefined;
};
export const DialogOk = ({
  open,
  title,
  message,
  textOk,
  callbackOk,
  callbackOnClose,
}: DialogOkProps) => {
  return (
    <Dialog open={open} onClose={callbackOnClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={callbackOk} autoFocus>
            {textOk || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DialogOkCancelProps = {
  readonly open: boolean;
  readonly title?: string;
  readonly message?: string;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
  readonly textOk?: string;
  readonly textCancel?: string;
  readonly isAutoFocusCancel?: boolean;
  readonly children?: React.ReactNode;
};
export const DialogOkCancel = ({
  open,
  title,
  message,
  callbackOk,
  callbackCancel,
  textOk,
  textCancel,
  isAutoFocusCancel,
  children,
}: DialogOkCancelProps) => {
  return (
    <Dialog open={open} onClose={callbackCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <section>{children}</section>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={callbackOk} autoFocus={!isAutoFocusCancel}>
          {textOk || 'OK'}
        </Button>
        <Button variant="outlined" onClick={callbackCancel} autoFocus={isAutoFocusCancel}>
          {textCancel || 'キャンセル'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const UpdateDialog: React.FC<{ registration: ServiceWorkerRegistration }> = ({
  registration,
}) => {
  const [open, setOpen] = React.useState(!!registration.waiting);

  React.useEffect(() => {
    setOpen(true);

    const timer = setInterval(() => {
      registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      setOpen(false);
      window.location.reload();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [registration.waiting]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>アプリの更新</DialogTitle>
      <DialogContent>
        <DialogContentText>アプリが更新されました。</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
