import { dataCart } from './OrderCart';
import { dataCoupons } from '@common/data/OrderCoupons';
import { dataDeliveryFee } from './OrderDeliveryFee';
import { dataDeliveryTo } from './OrderDeliveryTo';
import { dataDeliveryTime } from './OrderDeliveryTime';
import { dataDiscount } from './OrderDiscount';
import { dataOrderHistory } from './OrderHistory';
import { dataOrderPhoneNumber } from './OrderPhoneNumber';
export { DeliveryTime, DeliveryTimeOption } from './OrderDeliveryTime';
export type { OrderItemState, OrderState, OrderItem } from './OrderHistory';

export const dataOrder = {
  cart: dataCart,
  coupons: dataCoupons,
  deliveryTime: dataDeliveryTime,
  deliveryFee: dataDeliveryFee,
  deliveryTo: dataDeliveryTo,
  discount: dataDiscount,
  history: dataOrderHistory,
  phoneNumber: dataOrderPhoneNumber,
};
