import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';
import { StockCount, shopItemStock } from './ShopItemStock';
import { shopItemMaker } from './ShopItemMaker';
import { shopSelected } from './Shop';

export type ShopItemMaster = {
  // readonly shopId: number; // 配達元が異なる場合にカートを分ける。未実装
  readonly itemId: string; // EAN(JAN) code
  readonly name: string;
  readonly categoryId: string;
  readonly image: Array<string>;
  readonly priority: number;
  readonly price: number;
  readonly size: number;
  readonly weight: number;
  readonly makerId?: string;
  readonly makerName?: string;
  readonly brand?: string;
  readonly copy?: string;
  readonly description?: string;
  readonly allergen?: string;
  readonly expiration?: string;
  readonly bestByDate?: string;
  readonly limitedStock?: number;
};

export type ShopItemMasterStock = ShopItemMaster & StockCount;

// 商品リスト
// export const shopItemsMasterState = atom<ShopItemMaster[]>({
//   key: RecoilKeys.SHOP_ITEM_MASTER_STATE,
// 	// default: []
//   default: [
// 		{itemId: "2001010000026", name: "がっつり弁当", categoryName: "ランチスペシャル", maker:"シェイプ", brand:"シェイプのお弁当", price: 500, size:1, weight: 800, image: [p2001010000026_01, p2001010000026_02], allergen: "確認中", expiration: "当日中", copy: "ボリューム満点！揚げ物や肉がメインのお弁当。しっかりとエネルギーチャージをしたい貴方に！", description: "揚げ物やお肉料理がメインのシェイプ名物ボリューム満点のお弁当。栄養のバランスが偏らないように副菜やお漬物も添えてあります。※お惣菜の種類は日替わりです。お料理が写真と違う場合がございます。"},
// 		{itemId: "2001010000019", name: "あっさり弁当", categoryName: "ランチスペシャル", maker:"シェイプ", brand:"シェイプのお弁当", price: 500, size:1, weight:800, image: [p2001010000019_01, p2001010000019_02], allergen: "確認中", expiration: "当日中", copy: "お魚と野菜のお惣菜がメインのヘルシー弁当。健康にも気を使いたい貴方に！", description: "お魚やお野菜を使った惣菜がメインのお弁当。お魚の良質なタンパク質や、季節のお野菜でバランスの取れたお食事を頂けます。※お惣菜の種類は日替わりです。お料理が写真と違う場合がございます。"},
// 	]
// });
export const shopItemsMasterState = atom<ShopItemMaster[]>({
  key: RecoilKeys.SHOP_ITEM_MASTER_STATE,
  effects: [
    ({ setSelf, trigger, getPromise }) => {
      console.log('shopItemsMasterState effect');
      if (trigger === 'get') {
        console.log("shopItemsMasterState trigger is 'get'");
        const initialize = async () => {
          const shop = await getPromise(shopSelected);
          return api.fetchItems(shop.shopId);
        };

        setSelf(initialize());
      }
    },
  ],
});

// 特定商品の情報
export const shopItemMasterSelector = selectorFamily<ShopItemMasterStock | undefined, string>({
  key: RecoilKeys.SHOP_ITEM_MASTER_ITEM,
  get:
    (itemId) =>
    ({ get }) => {
      const item = get(shopItemsMasterState).find((item) => item.itemId === itemId);
      const makerName = item?.makerId ? get(shopItemMaker(item.makerId))?.makerName : undefined;
      const stockCount = get(shopItemStock(itemId))?.stockCount || 0;
      return item && stockCount !== undefined ? { ...item, makerName, stockCount } : undefined;
    },
});

// 特定商品最新情報を更新
const useShopItemMasterUpdate = () =>
  useRecoilCallback(
    ({ set }) =>
      // TODO: oldItemMasterは引数で渡す必要なさそうなので、後日確認する
      async (itemId: string, oldItemMaster: ShopItemMaster[]) => {
        const newShopItemMaster: ShopItemMaster = await api.fetchItem(itemId);
        set(shopItemsMasterState, () => {
          return oldItemMaster.map((shopItemMaster) => {
            return shopItemMaster.itemId === itemId ? newShopItemMaster : shopItemMaster;
          });
        });
      },
    []
  );

const useShopItemMasterRefresh = () =>
  useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const shop = await snapshot.getPromise(shopSelected);
        set(shopItemsMasterState, await api.fetchItems(shop.shopId));
      },
    []
  );

// 検索条件
type FilterCondition = {
  keyword: string;
};
const filterConditionState = atom<FilterCondition>({
  default: { keyword: '' },
  key: RecoilKeys.SHOP_ITEM_MASTER_FILTER_CONDITION_STATE,
});

// 商品の検索
const shopItemMasterFiltered = selector({
  key: RecoilKeys.SHOP_ITEM_MASTER_FILTERED,
  get: ({ get }) => {
    const items = get(shopItemsMasterState);
    const { keyword } = get(filterConditionState);

    // let isMultipleSearch = false;
    let filteredItems: ShopItemMaster[] = [];
    // // カテゴリ検索
    // if (categoryId !== 'all') {
    //   filteredItems = items.filter((managementItem) => managementItem.categoryId === categoryId);
    //   isMultipleSearch = true;
    // }

    // キーワード検索
    if (keyword !== '') {
      // 複数検索の場合、フィルタ対象を変える
      // const filterTarget = isMultipleSearch ? filteredItems : items;
      const filterTarget = items;
      // キーワードをスペース区切りの配列に変換
      const keywords = keyword.split(/\s+/);

      filteredItems = filterTarget.filter((managementItem) => {
        // 全てのプロパティが検索対象となる
        for (const value of Object.values(managementItem)) {
          // キーワードが全て含まれている場合true
          const isInclude = keywords.every((keyword) => String(value).indexOf(keyword) !== -1);
          if (isInclude) return true;
        }

        return false;
      });
    }

    // 在庫数を足して、在庫順にソートして返却
    return filteredItems
      .map((filteredItem) => {
        return get(shopItemMasterSelector(filteredItem.itemId));
      })
      .sort((a, b) => {
        return (b?.stockCount ?? 0) - (a?.stockCount ?? 0);
      });
  },
});

export const dataShopItemMaster = {
  useShopItemsMasterState: () => useRecoilState(shopItemsMasterState),
  useAllShopItemsLoadable: () => useRecoilValueLoadable(shopItemsMasterState),
  useShopItemRefresher: () => useShopItemMasterRefresh(),
  useShopItemMaster: (itemId: string) => useRecoilValue(shopItemMasterSelector(itemId)),
  useShopItemMasterUpdate,
  useShopItemMasterFiltered: () => useRecoilValue(shopItemMasterFiltered),
  useFilterCondition: () => useRecoilState(filterConditionState),
};
