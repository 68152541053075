import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dataOrder } from '@common/data/Order';
import { useClickActionButtonSound, useClickNavigationButtonSound } from '@common/data/SoundEffect';
import { CartItemsSkeleton, CartPriceSkeleton } from './Skeleton';
import { SelectDeliveryTimeDialog } from './SelectDeliveryTimeDialog';
import { isToday } from '@common/utils/etc';
import { OrderStepper } from './OrderStepper';
import { SelectSpotDialog } from './SelectSpotDialog';

export const OrderCart = () => {
  const navigate = useNavigate();
  const playClickNavigationButtonSound = useClickNavigationButtonSound();
  const playClickActionButtonSound = useClickActionButtonSound();

  const deliveryTimeSlotOptions = dataOrder.deliveryTime.useDeliveryTimeOptionsOfSelectedDate();
  const setDeliveryTime = dataOrder.deliveryTime.useSetDeliveryTime();
  const isAvailableDeliveryTime = dataOrder.deliveryTime.useIsAvailableDeliveryTimeSlot();
  console.info(isAvailableDeliveryTime);
  const cartReady = dataOrder.cart.useIsCartReadyToBuy();
  const [select, setSelect] = useState(
    dataOrder.deliveryTime.useDeliveryFirstAvailableTimeOptionIndex()
  );

  const selectedDate = dataOrder.cart.useCartDate();
  const setCartDate = dataOrder.cart.useSetCartDate();
  const [openSelectDeliveryTimeDialog, setOpenSelectDeliveryTimeDialog] = React.useState(false);
  const [openSelectSpotDialog, setOpenSelectSpotDialog] = React.useState(false);
  const selectedSpot = dataOrder.deliveryTo.useSpotSelected();

  const changeDateToTomorrow = async () => {
    // 当日の指定枠が埋まった、もしくはすべて終了してしまった場合は翌日の日時をセット
    if (isToday(selectedDate)) {
      const nextDate: number = selectedDate.getDate() + 1;
      const newSelectedDate = selectedDate;
      newSelectedDate.setDate(nextDate);
      await setCartDate(newSelectedDate);
      await setSelect(0);
    }
  };

  // 選択しているお届け時間が変わる際に状態を更新
  useEffect(() => {
    setDeliveryTime(deliveryTimeSlotOptions[select]);
  }, [select, deliveryTimeSlotOptions, setDeliveryTime]);

  return (
    <div className="w-screen h-screen flex flex-col">
      <SelectDeliveryTimeDialog
        open={openSelectDeliveryTimeDialog}
        currentIndex={select}
        setIndex={(index: number) => setSelect(index)}
        callbackOk={() => {
          playClickActionButtonSound();
          setOpenSelectDeliveryTimeDialog(false);
        }}
        callbackCancel={() => {
          playClickActionButtonSound();
          setOpenSelectDeliveryTimeDialog(false);
        }}
      />
      <SelectSpotDialog
        isOpen={openSelectSpotDialog}
        closeCallback={() => {
          setOpenSelectSpotDialog(false);
        }}
      />
      <section className="p-6" style={{ backgroundColor: 'rgb(251,240,221)' }}>
        <div className="w-1/2 mx-auto mb-2">
          <OrderStepper activeStep={0} />
        </div>
        <div className="mb-2 text-4xl font-bold text-center">ご注文内容の確認</div>
        <div className="mb-4 text-md text-center">
          以下の注文内容でよろしければ「次へ」ボタンを押してご注文を続けてください。
        </div>

        <div className="flex flex-row">
          <div className="flex flex-col overflow-y-auto pr-6">
            <div className="text-xl font-bold mb-2">ご注文商品</div>
            <React.Suspense fallback={<CartItemsSkeleton></CartItemsSkeleton>}>
              <CartItems />
            </React.Suspense>
          </div>
          <div className="flex flex-col w-2/5">
            <div className="w-full flex flex-col justify-center">
              <div className="text-xl font-bold mb-2">お届け予定時刻</div>
              <div className="text-2xl rounded-xl p-4" style={{ backgroundColor: 'white' }}>
                {select < 0 || !isAvailableDeliveryTime ? (
                  isToday(selectedDate) ? (
                    <>
                      <div className="text-warning mb-4">本日はお届け可能な時間がありません。</div>
                      <div
                        className="w-full py-2 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-primary border-primary border-4 mr-4"
                        onClick={async () => {
                          playClickActionButtonSound();
                          await changeDateToTomorrow();
                          await setOpenSelectDeliveryTimeDialog(true);
                        }}
                      >
                        明日のお届け時間の選択
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-warning mb-4">
                        申し訳ございません。お届け可能な時間がありません。
                      </div>
                      <div
                        className="w-full py-2 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-4 mr-4"
                        onClick={() => {
                          playClickActionButtonSound();
                          navigate('/');
                        }}
                      >
                        トップへ戻る
                      </div>
                    </>
                  )
                ) : deliveryTimeSlotOptions[select] ? (
                  <>
                    <div className="flex items-center">
                      <div className="grow">
                        <span className="text-sm mr-2">
                          {selectedDate.getMonth() + 1 + '月' + selectedDate.getDate() + '日 '}
                        </span>
                        <span className="font-bold">
                          {deliveryTimeSlotOptions[select].toStringTime('-')}
                        </span>
                      </div>
                      <div>
                        <div
                          className="p-2 px-6 text-sm rounded-full cursor-pointer font-bold text-white bg-primary"
                          onClick={() => {
                            playClickActionButtonSound();
                            setOpenSelectDeliveryTimeDialog(true);
                          }}
                        >
                          変更
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {'ご指定のお届け時間に空きがございません'}
                    <div
                      className="w-1/2 py-2 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-primary border-primary border-4 mr-4"
                      onClick={() => {
                        playClickActionButtonSound();
                        setOpenSelectDeliveryTimeDialog(true);
                      }}
                    >
                      お届け時間の選択
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="w-full flex flex-col justify-center mt-2">
              <div className="text-xl font-bold mb-2">お届け先</div>
              <div className="rounded-xl " style={{ backgroundColor: 'white' }}>
                <div className="flex flex-row text-xl items-center justify-center p-4 gap-2">
                  {selectedSpot.image.length !== 0 ? (
                    <img
                      alt="配送先画像"
                      src={selectedSpot.image[0]}
                      className="object-cover rounded-l-xl h-24"
                    />
                  ) : (
                    <p className="">配送先画像なし</p>
                  )}
                  <p className="font-bold">{selectedSpot.spotName}</p>
                  <div
                    className="p-2 px-6 ml-auto text-sm rounded-full cursor-pointer font-bold text-white bg-primary"
                    onClick={() => {
                      playClickActionButtonSound();
                      setOpenSelectSpotDialog(true);
                    }}
                  >
                    変更
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col justify-center mt-2">
              <div className="text-xl font-bold">お支払い</div>
              <div className="text-warning text-sm mb-2">
                <span className="font-bold">PayPay残高払い・後払い</span>
                <span>をご利用いただけます。</span>
              </div>
              <React.Suspense fallback={<CartPriceSkeleton></CartPriceSkeleton>}>
                <CartPrice />
              </React.Suspense>
            </div>
          </div>
        </div>
      </section>
      <footer className="fixed bottom-0 left-0 w-full bg-white p-2">
        <div className="grid grid-cols-3 gap-4 items-end">
          {cartReady ? (
            isAvailableDeliveryTime ? (
              <>
                <div
                  className="col-start-1 col-end-1 p-3 rounded-full cursor-pointer
          text-center text-2xl font-bold text-primary border-primary border-4"
                  onClick={() => {
                    playClickNavigationButtonSound();
                    navigate('/shop');
                  }}
                >
                  戻る
                </div>
                <div
                  className="col-start-3 col-end-3 p-4 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-white bg-primary"
                  onClick={() => {
                    playClickNavigationButtonSound();
                    navigate('/order/phoneNumber');
                  }}
                >
                  次へ
                </div>
              </>
            ) : (
              <div
                className="w-full py-3 rounded-full cursor-pointer
                text-center text-2xl font-bold text-warningContrast bg-warning"
                onClick={() => {
                  select < 0 && isToday(selectedDate) && changeDateToTomorrow();
                  playClickActionButtonSound();
                  setOpenSelectDeliveryTimeDialog(true);
                }}
              >
                お届け時間をご確認ください
              </div>
            )
          ) : (
            <div
              className="w-full py-3 rounded-full cursor-pointer
                  text-center text-2xl font-bold text-warningContrast bg-warning"
              onClick={() => {
                playClickNavigationButtonSound();
                navigate('/shop');
              }}
            >
              カートの内容をご確認ください
            </div>
          )}
        </div>
      </footer>
    </div>
  );
};

const CartPrice = () => {
  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const cartPrice = dataOrder.cart.useCartPrice();
  const deliveryFee = dataOrder.deliveryFee.useDeliveryFee();
  const discount = dataOrder.discount.useDiscount();
  const totalPrice = cartPrice + deliveryFee + discount;

  return (
    <div className="rounded-xl px-4 py-3" style={{ backgroundColor: 'white' }}>
      <div className="w-full flex flex-row justify-between">
        <div className="">商品合計（{cartTotalCount}点）</div>
        <div className="">
          <span className="">{cartPrice.toLocaleString()}</span>
          <span className="text-lg"> 円</span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between">
        <div className="">送料</div>
        <div className="">
          <span className="">{deliveryFee.toLocaleString()}</span>
          <span className="text-lg"> 円</span>
        </div>
      </div>
      {discount > 0 && (
        <div className="w-full flex flex-row justify-between">
          <div className="">割引</div>
          <div className="">
            <span className="">{discount.toLocaleString()}</span>
            <span className="text-lg"> 円</span>
          </div>
        </div>
      )}
      <div className="w-full mt-1 pt-2 flex flex-row justify-between border-t-2 border-black">
        <div className="">お支払い合計金額：</div>
        <div className="">
          <span className="text-2xl font-bold">{totalPrice.toLocaleString()}</span>
          <span className="text-lg font-bold"> 円</span>
        </div>
      </div>
    </div>
  );
};

const CartItems = () => {
  const cartItems = dataOrder.cart.useCartItems();

  return (
    <div className="grid grid-cols-2 gap-4">
      {cartItems.map((item, index) => (
        <div key={item.itemId} className="rounded-xl bg-white">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <img
                alt="商品画像"
                src={item.image[0]}
                className="w-36 h-24 m-3 object-cover rounded-xl"
              />
            </div>
            <div className="grow flex flex-col m-2 mt-4">
              <div className="text-base line-clamp-1">{item.makerName}</div>
              <div className="font-bold line-clamp-2 mb-4">{item.name}</div>

              <div className="">
                <span className="text-sm">数量:</span>
                <span className="mr-2">{item.count}</span>
                <span className="text-sm"> ¥</span>
                <span className="">{item.price.toLocaleString()}</span>
              </div>
              <div className="mt-1 flex flex-col ">
                <div className="text-right">
                  <div>
                    <React.Suspense fallback={<></>}>
                      <ItemMessage index={index} itemId={item.itemId} itemCount={item.count} />
                    </React.Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ItemMessage = ({
  index,
  itemCount,
}: {
  index: number;
  itemId: string;
  itemCount: number;
}) => {
  const addableByStock = dataOrder.cart.useCartItemsAddableCountByStock();

  return addableByStock[index] < 0 ? (
    <div className="text-right relative">
      <span className=" px-2 py-1 rounded text-warningContrast bg-warning text-xs">
        残り在庫数が {addableByStock[index] + itemCount} です 購入数を減らしてください
      </span>
    </div>
  ) : (
    <div></div>
  );
};
