import {
  selector,
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';
import { shopItemsMasterState } from './ShopItemMaster';
import { shopItemMasterSelector, ShopItemMasterStock } from './ShopItemMaster';
import { limitationByTodaysLunch } from './TodaysLunch';
import { autoRefreshTimeLimitationCheckState } from './AutoRefreshTime';
import { cartState } from './OrderCart';
import { shopSelected } from './Shop';

export type ShopItemCategory = {
  categoryId: string;
  categoryName: string;
  priority: number;
};

type ShopCategoryState = ShopItemCategory[];

const shopCategoryState = selector<ShopCategoryState>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_STATE,
  get: ({ get }) => {
    const shop = get(shopSelected);
    return api.fetchCategories(shop.shopId);
  },
});

// 利用可能なカテゴリに限定
const availableCategories = selector<ShopCategoryState>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_NAME,
  get: ({ get }) => {
    return limitationByTodaysLunch.filterAvailableCategories(
      get(shopCategoryState),
      get(autoRefreshTimeLimitationCheckState),
      get(cartState).deliveryDate
    );
  },
});

// カテゴリ名
const categoryName = selectorFamily<string, string>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_NAME,
  get:
    (categoryId) =>
    ({ get }) => {
      const category = get(shopCategoryState).find((item) => item.categoryId === categoryId);
      return category ? category.categoryName : '';
    },
});

// カテゴリ内商品一覧
const categoryItems = selectorFamily<ShopItemMasterStock[], string>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_ITEMS,
  get:
    (categoryId) =>
    ({ get }) => {
      return get(shopItemsMasterState)
        .filter((item) => item.categoryId === categoryId)
        .map((item) => get(shopItemMasterSelector(item.itemId)))
        .filter((item): item is ShopItemMasterStock => item !== undefined);
    },
});

// カテゴリ内商品一覧のうち在庫があるもの
const categoryItemsInStock = selectorFamily<ShopItemMasterStock[], string>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_ITEMS_IN_STOCK,
  get:
    (categoryId) =>
    ({ get }) => {
      return get(categoryItems(categoryId)).filter((item) => item.stockCount > 0);
    },
});

// カテゴリ内商品一覧のうち在庫がないもの
const categoryItemsSoldOut = selectorFamily<ShopItemMasterStock[], string>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_ITEMS_IN_STOCK,
  get:
    (categoryId) =>
    ({ get }) => {
      return get(categoryItems(categoryId)).filter((item) => item.stockCount <= 0);
    },
});

// 在庫のあるもので特定の最大数を満たさない場合、在庫のないもので最大数となるようにする
const categoryItemsInLimit = selectorFamily<ShopItemMasterStock[], string>({
  key: RecoilKeys.SHOP_ITEM_CATEGORY_ITEMS_IN_LIMIT,
  get:
    (categoryId) =>
    ({ get }) => {
      const MAX_ITEM_SIZE = 5;
      // 在庫のある商品
      const itemsInStock = get(categoryItemsInStock(categoryId)).slice(0, MAX_ITEM_SIZE);
      // 特定の最大数を満たさない場合
      if (itemsInStock.length < MAX_ITEM_SIZE) {
        // 在庫のない商品で残っている数を満たす
        const itemsSoldOut = get(categoryItemsSoldOut(categoryId)).slice(
          0,
          MAX_ITEM_SIZE - itemsInStock.length
        );
        return [...itemsInStock, ...itemsSoldOut];
      }
      return itemsInStock;
    },
});

// export
export const dataShopItemCategory = {
  useCategories: () => useRecoilValue(availableCategories),
  useCategoriesLoadable: () => useRecoilValueLoadable(availableCategories),
  useCategoryRefresher: () => useRecoilRefresher_UNSTABLE(availableCategories),
  useCategoryName: (categoryId: string) => useRecoilValue(categoryName(categoryId)),
  useCategoryItems: (categoryId: string) => useRecoilValue(categoryItems(categoryId)),
  useCategoryItemsInStock: (categoryId: string) => useRecoilValue(categoryItemsInStock(categoryId)),
  useCategoryItemsSoldOut: (categoryId: string) => useRecoilValue(categoryItemsSoldOut(categoryId)),
  useCategoryItemsInLimit: (categoryId: string) => useRecoilValue(categoryItemsInLimit(categoryId)),
};
