import { selector, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { shopItemMasterSelector } from './ShopItemMaster';
import { couponsState } from './OrderCoupons';

export const discountState = selector<number>({
  key: RecoilKeys.DISCOUNT_STATE,
  get: ({ get }) => {
    let discount = 0;
    const coupons = get(couponsState);
    console.log(coupons)
    for (const coupon of coupons){
      discount += get(shopItemMasterSelector(coupon.itemId))?.price ?? 0
    }
    console.log('discount :' + discount)
    return discount
  },
});

export const dataDiscount = {
  useDiscount: () => useRecoilValue(discountState),
};
