import { useEffect } from 'react';
import moment from 'moment';
import { CONST } from '../global';
import {
  atom,
  selector,
  useRecoilValue,
  useSetRecoilState,
  useRecoilCallback,
  useRecoilValueLoadable,
  selectorFamily,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { cartState } from './OrderCart';
import { api, APIOperatingDaysResponse, APIDeliveryAvailabilityShopId } from './Api';
import { spotSelectedIdState } from './OrderDeliveryTo';
import { shopSelected } from './Shop';

export class DeliveryTime {
  readonly date: Date;
  readonly beginHour: number;
  readonly beginMinute: number;
  readonly endHour: number;
  readonly endMinute: number;

  constructor(
    year: number,
    month: number,
    date: number,
    beginHour: number,
    beginMinute: number,
    endHour: number,
    endMinute: number
  ) {
    this.date = new Date(year, month, date);
    this.beginHour = beginHour;
    this.beginMinute = beginMinute;
    this.endHour = endHour;
    this.endMinute = endMinute;
  }

  getTimeOfDate() {
    return this.date.getTime();
  }

  getTimeOfBegin() {
    return new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate(),
      this.beginHour,
      this.beginMinute
    ).getTime();
  }

  getTimeOfEnd() {
    return new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate(),
      this.endHour,
      this.endMinute
    ).getTime();
  }

  toStringDate() {
    const year = this.date.getFullYear();
    const month = ('00' + (this.date.getMonth() + 1)).slice(-2);
    const day = ('00' + this.date.getDate()).slice(-2);
    return `${year}年${month}月${day}日`;
  }

  toStringLimitTime() {
    const beginDate = new Date(this.getTimeOfBegin());
    beginDate.setMinutes(
      beginDate.getMinutes() +
        CONST.PICKUP_DEADLINE_MINUTES +
        CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES
    );
    return `${beginDate.getHours()}:${('0' + beginDate.getMinutes()).slice(-2)}`;
  }

  toStringTime(connector: string) {
    return (
      this.beginHour +
      ':' +
      ('0' + this.beginMinute).slice(-2) +
      connector +
      this.endHour +
      ':' +
      ('0' + this.endMinute).slice(-2)
    );
  }
}

export class DeliveryTimeOption extends DeliveryTime {
  readonly available: number; // 残数

  constructor(
    year: number,
    month: number,
    date: number,
    beginHour: number,
    beginMinute: number,
    endHour: number,
    endMinute: number,
    available: number
  ) {
    super(year, month, date, beginHour, beginMinute, endHour, endMinute);
    this.available = available;
  }
}

// 選択した配達時刻
export const deliveryTimeState = atom<DeliveryTimeOption>({
  key: RecoilKeys.DELIVERY_TIME_STATE,
  default: undefined,
});

// すべての配達可能日（複数日）
export const deliveryTimeOption = selectorFamily({
  key: RecoilKeys.DELIVERY_TIME_OPTION,
  get:
    (spotIdParam?: string) =>
    async ({ get }) => {
      get(deliveryTimeUpdateTrigger);
      // spotIdが指定されていない場合は、選択されているspotIdを取得
      const spotId = spotIdParam ?? get(spotSelectedIdState);
      try {
        const deliveryTimes = await api.fetchDeliveryTimeOptions(spotId);
        return deliveryTimes as DeliveryTimeOption[];
      } catch (error) {
        console.error(error);
        return [] as DeliveryTimeOption[];
      }
    },
});

// shop内のspotについて、営業日であるかを辞書型で保持
export const deliveryOperatingDaysSpotsStatus = atom<APIOperatingDaysResponse | null>({
  key: RecoilKeys.DELIVERY_OPERATING_DAYS_SPOTS_STATUS,
  effects: [
    ({ setSelf, trigger, getPromise }) => {
      if (trigger === 'get') {
        const initialize = async () => {
          const shop = await getPromise(shopSelected);
          api
            .fetchIsOperatingDayShopId(shop.shopId)
            .then((operatingDaysResponse) => {
              setSelf(operatingDaysResponse);
            })
            .catch(() => {
              console.log('ERROR DELIVERY_OPERATING_DAYS_SPOTS_STATUS');
              setSelf(null);
            });
        };
        initialize();
      }
    },
  ],
});

// shop内のspotについて、現在時刻から配送可能であるかを辞書型で保持
// shopIdは、内部呼び出しでrecoilの循環呼び出しを防ぐため
export const deliveryAvailabilitySpotsStatus = selectorFamily({
  key: RecoilKeys.DELIVERY_AVAILABILITY_SPOTS_STATUS,
  get:
    (shopId?: string) =>
    async ({ get }) => {
      get(deliveryTimeUpdateTrigger);
      try {
        if (!shopId) {
          const shop = get(shopSelected);
          shopId = shop.shopId;
        }
        const response = await api.fetchDeliveryAvailabilityShopId(shopId);
        return response; // APIからのレスポンスをそのまま返す
      } catch (error) {
        console.error(error);
        return {} as APIDeliveryAvailabilityShopId;
      }
    },
});

// 営業日か否か shop内のspotが１つでも配達枠がある日は営業日
const isOperatingDay = selector({
  key: RecoilKeys.IS_OPERATING_DAY,
  get: ({ get }) => {
    const status = get(deliveryOperatingDaysSpotsStatus);
    if (status) {
      return Object.values(status).some((isOperating) => isOperating);
    }
    return false;
  },
});

// shop内のspotのいずれかが今から配達可能であるか否か
export const isAnySpotAvailableForDeliveryToday = selectorFamily({
  key: RecoilKeys.IS_ANY_SPOTAVAILABLE_FOR_DELIVERY_TODAY,
  get:
    (shopId?: string) =>
    ({ get }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const status = get(deliveryAvailabilitySpotsStatus(shopId as string | undefined as any));
      return Object.values(status).some((isOperating) => isOperating);
    },
});

const deliveryTimeUpdateTrigger = atom({
  key: RecoilKeys.DELIVERY_TIME_UPDATE_TRIGGER,
  default: 0,
});

//すべての配達可能日を最新に更新
const useDeliveryTimeOptionRefresh = () => {
  const setUpdateTrigger = useSetRecoilState(deliveryTimeUpdateTrigger);
  return () => {
    setUpdateTrigger((currentValue) => currentValue + 1);
  };
};

// カートで指定している日の配達可能時間
const deliveryTimeOptionOfSelectedDate = selector<DeliveryTimeOption[]>({
  key: RecoilKeys.DELIVERY_TIME_OPTION_OF_SELECTED_DATE,
  get: ({ get }) => {
    const date = get(cartState).deliveryDate;
    return get(deliveryTimeOption(undefined as unknown as string)).filter(
      (slot) => slot.getTimeOfDate() === date.getTime()
    );
  },
});

// 注文で指定している日の配達可能時間
const deliveryTimeOptionOfOrder = selectorFamily<
  DeliveryTimeOption[],
  { deliveryTime: number; spotId: string }
>({
  key: RecoilKeys.DELIVERY_TIME_OPTION_OF_SELECTED_DATE,
  get:
    ({ deliveryTime, spotId }) =>
    ({ get }) => {
      return get(deliveryTimeOption(spotId)).filter(
        (slot) => slot.getTimeOfDate() === deliveryTime
      );
    },
});

// カートで指定している日の配達可能時間のうち、最初の空きスロットのindex
const deliveryDateFirstAvailableTimeOptionIndex = selector<number>({
  key: RecoilKeys.DELIVERY_FIRST_AVAILABLE_TIME_OPTION_INDEX,
  get: ({ get }) => {
    return get(deliveryTimeOptionOfSelectedDate).findIndex((slot) => slot.available > 0);
  },
});

// 配達可能時間のうち、最初の空きスロット
const deliveryDateFirstAvailableTimeOption = selector<DeliveryTimeOption | undefined>({
  key: RecoilKeys.DELIVERY_FIRST_AVAILABLE_TIME_OPTION,
  get: ({ get }) => {
    return get(deliveryTimeOption(undefined as unknown as string)).find(
      (slot) => slot.available > 0
    );
  },
});

const deliveryDateTodayFirstAvailableTimeOption = selector<DeliveryTimeOption | undefined>({
  key: RecoilKeys.DELIVERY_TODAY_FIRST_AVAILABLE_TIME_OPTION, // キー名を更新
  get: ({ get }) => {
    const today = moment().startOf('day'); // 当日の日付の始まりを取得
    const deliveryTimeOptions = get(deliveryTimeOption(undefined as unknown as string));

    // 当日の日付と一致し、かつ利用可能な最初の配送スロットを探す
    return deliveryTimeOptions.find((slot) => {
      const slotMoment = moment(slot.date).startOf('day');
      return slot.available > 0 && slotMoment.isSame(today, 'day');
    });
  },
});

// 配達可能日
export const deliveryDateOptions = selector<Date[]>({
  key: RecoilKeys.DELIVERY_DATE_OPTIONS,
  get: ({ get }) => {
    const array = get(deliveryTimeOption(undefined as unknown as string)).map((slot) =>
      slot.getTimeOfDate()
    );
    console.log(array);
    return Array.from(new Set(array)).map((slot) => new Date(slot));
  },
});

export const deliveryDateTodayOption = selector<Date[]>({
  key: RecoilKeys.DELIVERY_DATE_TODAY_OPTION,
  get: ({ get }) => {
    const deliveryTimeOptions = get(deliveryTimeOption(undefined as unknown as string));
    const today = moment().startOf('day'); // 当日の日付の始まりを取得

    const todaySlots = deliveryTimeOptions
      .filter((slot) => moment(slot.date).isSame(today, 'day'))
      .map((slot) => slot.getTimeOfDate());
    return Array.from(new Set(todaySlots)).map((slot) => new Date(slot));
  },
});

// 指定している配達時間の空いているスロット数
const deliveryTimeSlotCount = selector<number>({
  key: RecoilKeys.DELIVERY_TIME_SLOT_COUNT,
  get: ({ get }) => {
    const deliveryDate = get(deliveryTimeState);
    return deliveryDate && deliveryDate.available ? deliveryDate.available : 0;
  },
});

const isAvailableDeliveryTimeSlot = selector<boolean>({
  key: RecoilKeys.IS_AVAILABLE_DELIVERY_TIME_SLOT,
  get: ({ get }) => {
    return get(deliveryTimeSlotCount) >= 1;
  },
});

const useChangeDeliveryTime = () =>
  useRecoilCallback(
    ({ set }) =>
      async (
        orderId: string,
        deliveryTime: { deliveryTimeBegin: number; deliveryTimeEnd: number }
      ) => {
        await api.ChangeDeliveryTime({
          orderId: orderId,
          DeliveryTimeBegin: deliveryTime.deliveryTimeBegin / 1000,
          DeliveryTimeEnd: deliveryTime.deliveryTimeEnd / 1000,
        });
      },
    []
  );

const useDeliveryTimeOptionRefreshInterval = () => {
  const refresh = useDeliveryTimeOptionRefresh();
  useEffect(() => {
    const intervalId = setInterval(refresh, CONST.TIME_DELIVERY_TIME_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);
};

export const dataDeliveryTime = {
  useDeliveryTimeState: () => useRecoilValue(deliveryTimeState),
  useSetDeliveryTime: () => useSetRecoilState(deliveryTimeState),
  useDeliveryTimeOptions: () => useRecoilValue(deliveryTimeOption(undefined as unknown as string)),
  useDeliveryTimeOptionsLoadable: () =>
    useRecoilValueLoadable(deliveryTimeOption(undefined as unknown as string)),
  useDeliveryTimeOptionsOfSelectedDate: () => useRecoilValue(deliveryTimeOptionOfSelectedDate),
  useDeliveryTimeOptionsOfOrder: (deliveryTime: DeliveryTime, spotId: string) =>
    useRecoilValue(
      deliveryTimeOptionOfOrder({ deliveryTime: deliveryTime.getTimeOfDate(), spotId })
    ),
  useDeliveryFirstAvailableTimeOptionIndex: () =>
    useRecoilValue(deliveryDateFirstAvailableTimeOptionIndex),
  useDeliveryDateTodayFirstAvailableTimeOption: () =>
    useRecoilValue(deliveryDateTodayFirstAvailableTimeOption),
  useDeliveryFirstAvailableTimeOption: () => useRecoilValue(deliveryDateFirstAvailableTimeOption),
  useDeliveryDateOptions: () => useRecoilValue(deliveryDateOptions),
  useDeliveryDateTodayOption: () => useRecoilValue(deliveryDateTodayOption),
  useDeliveryTimeOptionRefresher: () => useDeliveryTimeOptionRefresh(),
  useDeliveryTimeOptionRefreshInterval: () => useDeliveryTimeOptionRefreshInterval(),
  useIsAvailableDeliveryTimeSlot: () => useRecoilValue(isAvailableDeliveryTimeSlot),
  useIsAnySpotAvailableForDeliveryToday: () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useRecoilValue(isAnySpotAvailableForDeliveryToday(undefined as any)),
  useDeliveryAvailabilitySpotsStatus: () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useRecoilValue(deliveryAvailabilitySpotsStatus(undefined as any)),
  useIsOperatingDay: () => useRecoilValue(isOperatingDay),
  useChangeDeliveryTime,
};
