export enum RecoilKeys {
  IS_LOGIN = 'IsLogin',
  SHOPS_STATE = 'ShopsState',
  SHOPS_INCLUDE_SPOTS = 'ShopsIncludeSpots',
  SHOPS_GET_SHOPS = 'ShopsGetShops',
  SHOP_SELECTED_ID_STATE = 'ShopSelectedIdState',
  SHOP_SELECTED = 'ShopSelected',

  SHOP_ITEM_MASTER_STATE = 'ShopItemMasterState',
  SHOP_ITEM_MASTER_ITEM = 'ShopItemMasterItem',
  SHOP_ITEM_MASTER_FILTER_CONDITION_STATE = 'ShopItemMasterFilterConditionState',
  SHOP_ITEM_MASTER_FILTERED = 'ShopItemMasterFiltered',

  SHOP_ITEM_CATEGORY_STATE = 'shopItemCategoryState',
  SHOP_ITEM_CATEGORY_NAME = 'shopItemCategoryName',
  SHOP_ITEM_CATEGORY_ITEMS = 'shopItemCategoryItems',
  SHOP_ITEM_CATEGORY_ITEMS_IN_STOCK = 'shopItemCategoryItemsInStock',
  SHOP_ITEM_CATEGORY_ITEMS_IN_LIMIT = 'shopItemCategoryItemsInLimit',

  SHOP_ITEM_OFFER_STATE = 'shopItemOfferState',
  SHOP_ITEM_OFFER_ITEMS = 'shopItemOfferItems',

  SHOP_ITEM_STOCK_ALL = 'shopItemStockAll',
  SHOP_ITEM_STOCK = 'shopItemStock',

  SHOP_ITEM_MASTER_ALL = 'shopItemMakerAll',
  // SHOP_ITEM_MAKER_BY_NAME = "shopItemMakerByName",
  SHOP_ITEM_MAKER = 'shopItemMaker',

  CART_STATE = 'cartState',
  CART_ITEMS = 'cartItems',
  CART_ITEMS_SORTED_REVERSE = 'cartItemsSortedReverse',
  CART_ITEM_COUNT = 'cartItemCount',
  CART_TOTAL_COUNT = 'cartTotalCount',
  CART_TOTAL_PRICE = 'cartTotalPrice',
  CART_TOTAL_SIZE = 'cartTotalSize',
  CART_TOTAL_WEIGHT = 'CART_TOTAL_WEIGHT',
  ITEM_AVAILABLE_COUNT = 'itemAvailableCount',
  ITEM_AVAILABLE_COUNT_BY_STOCK = 'itemAvailableCountByStock',
  ITEM_AVAILABLE_COUNT_BY_CART_SIZE = 'itemAvailableCountByCartSize',
  ITEM_AVAILABLE_COUNT_BY_CART_WEIGHT = 'ITEM_AVAILABLE_COUNT_BY_CART_WEIGHT',
  ITEM_AVAILABLE_COUNT_BY_CART_COUNT = 'itemAvailableCountByCount',
  CART_ITEMS_ADDABLE_COUNT_BY_STOCK = 'cartItemsAddableCountByStock',
  CART_ITEMS_ADDABLE_COUNT_BY_SIZE = 'CART_ITEMS_ADDABLE_COUNT_BY_SIZE',
  CART_ITEMS_ADDABLE_COUNT_BY_WEIGHT = 'CART_ITEMS_ADDABLE_COUNT_BY_WEIGHT',
  IS_CART_ITEMS_ADDABLE_BY_SIZE = 'isCartItemsAddableBySize',
  IS_CART_ITEMS_ADDABLE_BY_WEIGHT = 'IS_CART_ITEMS_ADDABLE_BY_WEIGHT',
  IS_CART_ITEMS_ADDABLE_BY_COUNT = 'IS_CART_ITEMS_ADDABLE_BY_COUNT',
  IS_CART_ITEMS_AVAILABLE_BY_TODAYS_LUNCH_DEADLINE = 'IS_CART_ITEMS_AVAILABLE_BY_TODAYS_LUNCH_DEADLINE',
  IS_CART_READY_TO_BUY = 'isCartReadyToBuy',

  DELIVERY_TO_STATE = 'deliveryToState',
  ORDERS_DELIVERY_SPOT_DETAIL_STATE = 'ordersDeliverySpotDetailState',
  SPOT_SELECTED_ID_STATE = 'SpotSelectedIdState',
  SPOT_SELECTED = 'SpotSelected',
  SPOT_AND_SHOP_IN_ORDER = 'SpotAndShopInOrder',

  DELIVERY_FEE_STATE = 'deliveryFeeState',
  DISCOUNT_STATE = 'discountFeeState',
  COUPONS_STATE = 'couponsState',

  DELIVERY_TIME_STATE = 'deliveryTimeState',
  DELIVERY_TIME_OPTION = 'deliveryTimeOption',
  DELIVERY_TIME_UPDATE_TRIGGER = 'deliveryTimeUpdateTrigger',
  DELIVERY_OPERATING_DAYS_SPOTS_STATUS = 'deliveryOperatingDaysSpotsStatus',
  DELIVERY_AVAILABILITY_SPOTS_STATUS = 'deliveryAvailabilitySpotsStatus',
  IS_OPERATING_DAY = 'isOperatingDay',
  IS_ANY_SPOTAVAILABLE_FOR_DELIVERY_TODAY = 'isAnySpotAvailableForDeliveryToday',
  DELIVERY_TIME_OPTION_OF_SELECTED_DATE = 'deliveryTimeOptionOfSelectedDate',
  DELIVERY_TIME_OPTION_OF_ORDER = 'deliveryTimeOptionOfOrder',
  DELIVERY_FIRST_AVAILABLE_TIME_OPTION_INDEX = 'deliveryFirstAvailableTimeOptionIndex',
  DELIVERY_FIRST_AVAILABLE_TIME_OPTION = 'deliveryFirstAvailableTimeOption',
  DELIVERY_TODAY_FIRST_AVAILABLE_TIME_OPTION = 'deliveryDateTodayFirstAvailableTimeOption',
  DELIVERY_DATE = 'deliveryDate',
  DELIVERY_DATE_OPTIONS = 'deliveryDateOptions',
  DELIVERY_DATE_TODAY_OPTION = 'deliveryDateTodayOption',

  ORDER_STATE = 'orderState',
  ORDER_ITEM = 'orderItem',
  ORDER_ITEMS_ON_DELIVERY = 'orderItemsOnDelivery',
  ORDER_COUNT_ON_DELIVERY = 'orderCountOnDelivery',
  ORDER_PLACE_ORDER = 'orderPlaceOrder',
  ORDER_PHONE_NUMBER = 'orderPhoneNumber',
  ORDER_PHONE_NUMBER_NO_HYPHEN = 'orderPhoneNumberNoHyphen',

  USER_STATE = 'userState',
  COGNITO_USER_INFO = 'cognitoUserInfo',

  AGREEMENT_STATE = 'agreementState',
  AGREEMENT_FIND = 'agreementFind',

  APP_MESSAGES_STATE_ALL = 'appMessagesStateAll',
  APP_MESSAGES_STATE = 'appMessagesState',
  APP_MESSAGES_MESSAGE = 'appMessagesMessage',

  AUTO_REFRESH_TIME_LIMITATION_CHECK_STATE = 'AUTO_REFRESH_TIME_LIMITATION_CHECK_STATE',
  DELIVERY_TIME_SLOT_COUNT = 'DELIVERY_TIME_SLOT_COUNT',
  IS_AVAILABLE_DELIVERY_TIME_SLOT = 'IS_AVAILABLE_DELIVERY_TIME_SLOT',

  WANTED_ROUTE = 'WANTED_ROUTE',
}
