import { selector, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { shopItemMasterSelector } from './ShopItemMaster';

export const deliveryFeeState = selector<number>({
  key: RecoilKeys.DELIVERY_FEE_STATE,
  get: ({ get }) => {
    // 送料商品コード XXXXXXXXXXXX
    const deliveryFee = get(shopItemMasterSelector('XXXXXXXXXXXX'))?.price ?? 0;
    console.log(get(shopItemMasterSelector('XXXXXXXXXXXX')));
    console.log('deliveryFee :' + deliveryFee);
    return deliveryFee;
  },
});

export const dataDeliveryFee = {
  useDeliveryFee: () => useRecoilValue(deliveryFeeState),
};
