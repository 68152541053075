import { atom, selector, useRecoilValue, useRecoilCallback, selectorFamily } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { shopItemMasterSelector, ShopItemMaster, shopItemsMasterState } from './ShopItemMaster';
import { shopItemStock } from './ShopItemStock';
import { deliveryDateOptions } from './OrderDeliveryTime';
import { limitationByTodaysLunch } from './TodaysLunch';
import { autoRefreshTimeLimitationCheckState } from './AutoRefreshTime';
import { CONST } from '../global';
import { Auth } from 'aws-amplify';
import { api } from './Api';
import { spotSelectedIdState } from './OrderDeliveryTo';
import { shopSelected } from './Shop';

type ItemCount = {
  itemId: string;
  count: number;
};

// 将来的には配達日ごとに複数のカートを表示したい
type CartState = {
  items: ItemCount[];
  deliveryDate: Date;
  shopId: string;
  spotId: string;
};

type ShopItemMasterCount = ShopItemMaster & ItemCount;

// カート
export const cartState = atom<CartState>({
  key: RecoilKeys.CART_STATE,
  effects: [
    ({ setSelf, onSet, trigger, getPromise }) => {
      console.log('cartState effect');
      if (trigger === 'get') {
        console.log("cartState trigger is 'get'");

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        Auth.currentAuthenticatedUser()
          .then(async (cognitoUser) => {
            let filteredItemsInCart: ItemCount[] = [];
            const selectedSpotId = await getPromise(spotSelectedIdState);
            const selectedShop = await getPromise(shopSelected);
            const groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];

            if (groups && groups.includes('VendingMachine')) {
              console.log('skip fetchCart grops:' + groups);
            } else {
              // 永続化されたカート情報を取得
              const storedCart = await api.fetchCart();
              const itemsInCart: ItemCount[] = JSON.parse(storedCart.items);

              // 商品一覧を取得
              const items = await getPromise(shopItemsMasterState);

              // 現在の商品一覧に存在しないカート内商品を省く
              filteredItemsInCart = itemsInCart.filter((itemInCart) => {
                return items.some((item) => item.itemId === itemInCart.itemId);
              });

              // 省かれた商品があった場合は、永続化を行う
              if (filteredItemsInCart.length !== itemsInCart.length) {
                await api.postCart({
                  items: JSON.stringify(filteredItemsInCart),
                  deliveryDate: today,
                  shopId: selectedShop.shopId,
                  spotId: selectedSpotId,
                });
              }
            }

            setSelf({
              items: filteredItemsInCart,
              deliveryDate: today,
              shopId: selectedShop.shopId,
              spotId: selectedSpotId,
            });
          })
          .catch(async () => {
            const selectedSpotId = await getPromise(spotSelectedIdState);
            const selectedShop = await getPromise(shopSelected);
            setSelf({
              items: [],
              deliveryDate: today,
              shopId: selectedShop?.shopId,
              spotId: selectedSpotId,
            });
          });
      }

      onSet((newState) => {
        Auth.currentAuthenticatedUser().then(async (cognitoUser) => {
          const groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
          if (groups && groups.includes('VendingMachine')) {
            console.log('skip onSet grops:' + groups);
          } else {
            await api.postCart({ ...newState, items: JSON.stringify(newState.items) });
          }
        });
        console.log('Upload cart state to remote DB: ', newState);
      });

      // Subscribe here
      // myRemoteStorage.onChange((state) => {
      //   setSelf(state); // Call asynchronously to change value
      // });

      // Cleanup remote storage subscription
      // return () => {
      //   myRemoteStorage.onChange(null);
      // };
    },
  ],
});

// カートに入れた商品詳細
const cartItemsSelector = selector({
  key: RecoilKeys.CART_ITEMS,
  get: ({ get }) => {
    const cart = get(cartState);
    return cart.items
      .map((cartItem) => {
        return {
          count: cartItem.count,
          ...get(shopItemMasterSelector(cartItem.itemId)),
        };
      })
      .filter((item): item is ShopItemMasterCount => item.name !== undefined);
  },
});

// カートに入れた商品詳細(入れた順にソート)
const cartItemsSortedReverseSelector = selector({
  key: RecoilKeys.CART_ITEMS_SORTED_REVERSE,
  get: ({ get }) => {
    const items = [...get(cartItemsSelector)];
    return items.reverse();
  },
});

// 指定の商品がカートに入っている数
const cartItemCount = selectorFamily<number, string>({
  key: RecoilKeys.CART_ITEM_COUNT,
  get:
    (itemId) =>
    ({ get }) => {
      const item = get(cartState).items.find((item) => item.itemId === itemId);
      return item ? item.count : 0;
    },
});

// カートの商品の合計数
const cartTotalCountSelector = selector<number>({
  key: RecoilKeys.CART_TOTAL_COUNT,
  get: ({ get }) => {
    return get(cartState).items.reduce((sum, item) => sum + item.count, 0);
  },
});

// カートの商品の合計価格
export const cartTotalPriceSelector = selector<number>({
  key: RecoilKeys.CART_TOTAL_PRICE,
  get: ({ get }) => {
    return get(cartState).items.reduce((sum, item) => {
      const price = get(shopItemMasterSelector(item.itemId))?.price;
      return price ? sum + item.count * price : sum;
    }, 0);
    //}, get(deliveryFeeState));
  },
});

// カートの商品の合計サイズ
const cartTotalSizSelector = selector<number>({
  key: RecoilKeys.CART_TOTAL_SIZE,
  get: ({ get }) => {
    return get(cartState).items.reduce((sum, item) => {
      const size = get(shopItemMasterSelector(item.itemId))?.size;
      return size ? sum + item.count * size : sum;
    }, 0);
  },
});

// カートの商品の合計重量
const cartTotalWeightSelector = selector<number>({
  key: RecoilKeys.CART_TOTAL_WEIGHT,
  get: ({ get }) => {
    return get(cartState).items.reduce((sum, item) => {
      const weight = get(shopItemMasterSelector(item.itemId))?.weight;
      return weight ? sum + item.count * weight : sum;
    }, 0);
  },
});

// カートに商品を追加
const useAddCartItem = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (itemId: string, count: number) => {
        const cartSize = await snapshot.getPromise(cartTotalSizSelector);
        const size = (await snapshot.getPromise(shopItemMasterSelector(itemId)))?.size;
        if (size && cartSize + size * count > CONST.MAX_CART_SIZE) {
          return false;
        }

        // カート重量制限
        const cartWeight = await snapshot.getPromise(cartTotalWeightSelector);
        const weight = (await snapshot.getPromise(shopItemMasterSelector(itemId)))?.weight;
        if (weight && cartWeight + weight * count > CONST.MAX_CART_WEIGHT) {
          return false;
        }

        // カート個数制限
        const cartCount = await snapshot.getPromise(cartTotalCountSelector);
        if (cartCount + count > CONST.MAX_CART_COUNT) {
          return false;
        }

        set(cartState, (prev) => {
          let exists = false;
          const newItems = prev.items.map((item) => {
            if (item.itemId === itemId) {
              exists = true;
              return { itemId: item.itemId, count: item.count + count };
            } else return item;
          });
          return {
            ...prev,
            items: exists ? newItems : [...prev.items, { itemId: itemId, count: count }],
          };
        });
        return true;
      },
    []
  );

// 指定の商品を削除
const useRemoveCartItem = () =>
  useRecoilCallback(
    ({ set }) =>
      (itemId: string) => {
        set(cartState, (prev) => {
          return {
            ...prev,
            items: prev.items.filter((item) => item.itemId !== itemId),
          };
        });
      },
    []
  );

// 個数を +1
const useIncrementCartItem = () =>
  useRecoilCallback(
    ({ set }) =>
      (itemId: string) => {
        set(cartState, (prev) => {
          return {
            ...prev,
            items: prev.items.map((item) => {
              return item.itemId === itemId ? { itemId: item.itemId, count: item.count + 1 } : item;
            }),
          };
        });
      },
    []
  );

// 個数を -1
const useDecrementCartItem = () =>
  useRecoilCallback(
    ({ set }) =>
      (itemId: string) => {
        set(cartState, (prev) => {
          return {
            ...prev,
            items: prev.items
              .map((item) => {
                return item.itemId === itemId
                  ? { itemId: item.itemId, count: item.count - 1 }
                  : item;
              })
              .filter((item) => item.count > 0),
          };
        });
      },
    []
  );

// カートを空にする
const useClearCart = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const dateOptions = await snapshot.getPromise(deliveryDateOptions);
        const selectedShop = await snapshot.getPromise(shopSelected);
        const selectedSpotId = await snapshot.getPromise(spotSelectedIdState);

        set(cartState, {
          items: [],
          deliveryDate: dateOptions.length !== 0 ? dateOptions[0] : today,
          shopId: selectedShop.shopId,
          spotId: selectedSpotId,
        });
      },
    []
  );

// 追加できる数（在庫数）
const itemAvailableCountByStock = selectorFamily<number, string>({
  key: RecoilKeys.ITEM_AVAILABLE_COUNT_BY_STOCK,
  get:
    (itemId) =>
    ({ get }) => {
      const stock = get(shopItemStock(itemId))?.stockCount;
      const cart = get(cartState).items.find((item) => item.itemId === itemId)?.count;
      return (stock ? stock : 0) - (cart ? cart : 0);
    },
});

// 追加できる数（カート容量）
const itemAvailableCountByCartSize = selectorFamily<number, string>({
  key: RecoilKeys.ITEM_AVAILABLE_COUNT_BY_CART_SIZE,
  get:
    (itemId) =>
    ({ get }) => {
      const cartSize = get(cartTotalSizSelector);
      const size = get(shopItemMasterSelector(itemId))?.size;
      return size ? Math.floor((CONST.MAX_CART_SIZE - cartSize) / size) : 0;
    },
});

// 追加できる数（カート重量）
const itemAvailableCountByCartWeight = selectorFamily<number, string>({
  key: RecoilKeys.ITEM_AVAILABLE_COUNT_BY_CART_WEIGHT,
  get:
    (itemId) =>
    ({ get }) => {
      const cartWeight = get(cartTotalWeightSelector);
      const weight = get(shopItemMasterSelector(itemId))?.weight;
      return weight ? Math.floor((CONST.MAX_CART_WEIGHT - cartWeight) / weight) : 0;
    },
});

// 追加できる数（総注文数）
const itemAvailableCountByCount = selectorFamily<number, string>({
  key: RecoilKeys.ITEM_AVAILABLE_COUNT_BY_CART_COUNT,
  get:
    (itemId) =>
    ({ get }) => {
      const count = get(cartTotalCountSelector);
      return CONST.MAX_CART_COUNT - count;
    },
});

// カートに入っている商品を追加可能な数（在庫数）
const cartItemsAddableCountByStock = selector<number[]>({
  key: RecoilKeys.CART_ITEMS_ADDABLE_COUNT_BY_STOCK,
  get: ({ get }) => {
    return get(cartState).items.map((item) => get(itemAvailableCountByStock(item.itemId)));
  },
});

// カートに入っている商品を追加可能な数（カート容量）
const cartItemsAddableCountBySize = selector<number[]>({
  key: RecoilKeys.CART_ITEMS_ADDABLE_COUNT_BY_SIZE,
  get: ({ get }) => {
    return get(cartState).items.map((item) => get(itemAvailableCountByCartSize(item.itemId)));
  },
});

// カートに入っている商品を追加可能な数（カート重量）
const cartItemsAddableCountByWeight = selector<number[]>({
  key: RecoilKeys.CART_ITEMS_ADDABLE_COUNT_BY_WEIGHT,
  get: ({ get }) => {
    return get(cartState).items.map((item) => get(itemAvailableCountByCartWeight(item.itemId)));
  },
});

// カートに入っている商品が追加できるかどうか（カート容量）
const isCartItemsAddableBySize = selector<boolean[]>({
  key: RecoilKeys.IS_CART_ITEMS_ADDABLE_BY_SIZE,
  get: ({ get }) => {
    return get(cartState).items.map((item) => get(itemAvailableCountByCartSize(item.itemId)) > 0);
  },
});

// カートに入っている商品が追加できるかどうか（カート重量）
const isCartItemsAddableByWeight = selector<boolean[]>({
  key: RecoilKeys.IS_CART_ITEMS_ADDABLE_BY_WEIGHT,
  get: ({ get }) => {
    return get(cartState).items.map((item) => get(itemAvailableCountByCartWeight(item.itemId)) > 0);
  },
});

// カートに入っている商品が追加できるかどうか（個数制限）
const isCartItemsAddableByCount = selector<boolean>({
  key: RecoilKeys.IS_CART_ITEMS_ADDABLE_BY_COUNT,
  get: ({ get }) => {
    return get(cartTotalCountSelector) < CONST.MAX_CART_COUNT;
  },
});

// カートに入っている商品が購入可能かどうか（本日のランチの注文時間制限）
const isCartItemsAvailableByTodaysLunchDeadline = selector<boolean[]>({
  key: RecoilKeys.IS_CART_ITEMS_AVAILABLE_BY_TODAYS_LUNCH_DEADLINE,
  get: ({ get }) => {
    return limitationByTodaysLunch.isCartItemsAvailable(
      get(cartState).items.map(
        (item) => get(shopItemMasterSelector(item.itemId))?.categoryId || ''
      ),
      get(autoRefreshTimeLimitationCheckState),
      get(cartState).deliveryDate
    );
  },
});

// カートに購入できないものがない状態か
const isCartReadyToBuy = selector<boolean>({
  key: RecoilKeys.IS_CART_READY_TO_BUY,
  get: ({ get }) => {
    return (
      get(cartItemsAddableCountByStock).every((count) => count >= 0) &&
      get(cartItemsAddableCountBySize).every((count) => count >= 0) &&
      get(cartItemsAddableCountByWeight).every((count) => count >= 0) &&
      get(isCartItemsAvailableByTodaysLunchDeadline).every((x) => x)
    );
  },
});

// 配達日をセット
const useSetCartDate = () =>
  useRecoilCallback(
    ({ set }) =>
      (deliveryDate: Date) => {
        set(cartState, (prev) => {
          return { ...prev, deliveryDate };
        });
      },
    []
  );

// export
export const dataCart = {
  useCartDate: () => useRecoilValue(cartState).deliveryDate,
  useCartItems: () => useRecoilValue(cartItemsSelector),
  useCartItemsSortedReverse: () => useRecoilValue(cartItemsSortedReverseSelector),
  useCartItemCount: (itemId: string) => useRecoilValue(cartItemCount(itemId)),
  useCartTotalCount: () => useRecoilValue(cartTotalCountSelector),
  useCartPrice: () => useRecoilValue(cartTotalPriceSelector),
  useAddCartItem,
  useRemoveCartItem,
  useIncrementCartItem,
  useDecrementCartItem,
  useClearCart,
  useItemAvailableCountByStock: (itemId: string) =>
    useRecoilValue(itemAvailableCountByStock(itemId)),
  useItemAvailableCountByCartSize: (itemId: string) =>
    useRecoilValue(itemAvailableCountByCartSize(itemId)),
  useItemAvailableCountByCartWeight: (itemId: string) =>
    useRecoilValue(itemAvailableCountByCartWeight(itemId)),
  useItemAvailableCountByCount: (itemId: string) =>
    useRecoilValue(itemAvailableCountByCount(itemId)),
  useCartItemsAddableCountByStock: () => useRecoilValue(cartItemsAddableCountByStock),
  useIsCartItemsAddableBySize: () => useRecoilValue(isCartItemsAddableBySize),
  useIsCartItemsAddableByWeight: () => useRecoilValue(isCartItemsAddableByWeight),
  useIsCartItemsAddableByCount: () => useRecoilValue(isCartItemsAddableByCount),
  useIsCartItemsAvailableByTodaysLunchDeadline: () =>
    useRecoilValue(isCartItemsAvailableByTodaysLunchDeadline),
  useIsCartReadyToBuy: () => useRecoilValue(isCartReadyToBuy),
  useSetCartDate,
};
