import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';
import { shopSelected } from './Shop';
import { useEffect } from 'react';
import { CONST } from '@common/global';

export type StockCount = {
  itemId: string;
  stockCount: number;
};

const shopItemsStockAllAtom = atomFamily<StockCount[], string>({
  key: RecoilKeys.SHOP_ITEM_STOCK_ALL,
  effects: (shopId: string) => [
    ({ setSelf, trigger }) => {
      console.log('shopItemsStockAllAtom effect');
      if (trigger === 'get') {
        console.log("shopItemsStockAllAtom trigger is 'get'");
        const shopItemsMaster = api.fetchStocks(shopId);
        setSelf(shopItemsMaster);
      }
    },
  ],
});

export const shopItemStock = selectorFamily({
  key: RecoilKeys.SHOP_ITEM_STOCK,
  get:
    (itemId) =>
    ({ get }) => {
      const selectedShop = get(shopSelected);
      return get(shopItemsStockAllAtom(selectedShop.shopId)).find((item) => item.itemId === itemId);
    },
});

//特定商品の最新在庫情報を更新
const useShopItemStockUpdate = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (itemId: string, oldItemStockMaster: StockCount[]) => {
        const selectedShop = await snapshot.getPromise(shopSelected);
        const newShopItemStock = await api.fetchStock(selectedShop.shopId, itemId);
        set(shopItemsStockAllAtom(selectedShop.shopId), () => {
          return oldItemStockMaster.map((shopItemStock) => {
            return shopItemStock.itemId === itemId && newShopItemStock
              ? newShopItemStock
              : shopItemStock;
          });
        });
      },
    []
  );

//在庫情報を更新
const useShopItemStockRefresh = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const selectedShop = await snapshot.getPromise(shopSelected);
        set(shopItemsStockAllAtom(selectedShop.shopId), await api.fetchStocks(selectedShop.shopId));
      },
    []
  );

const useShopItemStockRefreshInterval = () => {
  const refresh = useShopItemStockRefresh();
  useEffect(() => {
    const intervalId = setInterval(refresh, CONST.TIME_STOCK_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);
};

export const dataShopItemStock = {
  useShopItemsStockAllState: (shopId: string) => useRecoilState(shopItemsStockAllAtom(shopId)),
  useShopItemStockUpdate,
  useShopItemStockLoadable: (shopId: string) =>
    useRecoilValueLoadable(shopItemsStockAllAtom(shopId)),
  useShopItemStockRefresher: () => useShopItemStockRefresh(),
  useShopItemStockRefreshInterval,
};
