import {
  selector,
  useRecoilValue,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';
import { shopItemMasterSelector, ShopItemMaster, ShopItemMasterStock } from './ShopItemMaster';
import { shopSelected } from './Shop';

type OfferItem = {
  itemId: string;
  //message?: string;
};
type OfferState = OfferItem[];

// const offerState = atom<OfferState>({
//   key: RecoilKeys.SHOP_ITEM_OFFER_STATE,
// 	// default: [],
//   default: api.fetchOffer(),
// });
const offerState = selector<OfferState>({
  key: RecoilKeys.SHOP_ITEM_OFFER_STATE,
  get: ({ get }) => {
    const selectedShop = get(shopSelected);
    return api.fetchOffers(selectedShop.shopId);
  },
});

// おすすめ商品詳細
const offerItemsSelector = selector<ShopItemMaster[]>({
  key: RecoilKeys.SHOP_ITEM_OFFER_ITEMS,
  get: ({ get }) => {
    return get(offerState)
      .map((offerItem) => get(shopItemMasterSelector(offerItem.itemId)))
      .filter((item): item is ShopItemMasterStock => item !== undefined && item.stockCount > 0);
  },
});

// export
export const dataShopItemOffer = {
  // useOffer: () => useRecoilValue(offerState),
  useOfferItemsLoadable: () => useRecoilValueLoadable(offerState),
  useOfferItems: () => useRecoilValue(offerItemsSelector),
  useRefreshOfferItems: () => useRecoilRefresher_UNSTABLE(offerState),
};
