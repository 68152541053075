import { useState } from 'react';
import { dataOrder } from '@common/data/Order';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataShop } from '@common/data/Shop';
import { useClickActionButtonSound } from '@common/data/SoundEffect';
import {
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { dataDeliveryTime } from '@common/data/OrderDeliveryTime';

export const SelectSpotDialog = ({
  isOpen,
  closeCallback,
}: {
  isOpen: boolean;
  closeCallback: () => void;
}) => {
  const shop = dataShop.useShopSelected();
  const [latestSelectedId] = dataDeliveryTo.useSpotSelectedIdState();
  const [, setSelectedSpotId] = dataOrder.deliveryTo.useSpotSelectedIdState();
  const deliveryAvailabilitySpotsStatus = dataDeliveryTime.useDeliveryAvailabilitySpotsStatus();
  const isSpotEnable = (spotId: string): boolean => {
    return deliveryAvailabilitySpotsStatus?.[spotId] ?? false;
  };
  const [isOpenBackDrop, setIsOpenBackDrop] = useState(false);
  const playClickActionButtonSound = useClickActionButtonSound();

  const changeSpotCallback = async (spotId: string) => {
    playClickActionButtonSound();
    setIsOpenBackDrop(true);
    await new Promise((resolve) => setTimeout(resolve, 100)); // Backdropが表示されるまで少しの時間待つ
    if (spotId) {
      await setSelectedSpotId(spotId);
      await new Promise((resolve) => setTimeout(resolve, 1200)); // このダイアログを閉じるために、本当はAPIのレスポンスを待ちたい
    }
    setIsOpenBackDrop(false);
    closeCallback();
  };

  const selectStyle = (spotId: string) => (latestSelectedId === spotId ? 'bg-primary' : 'border');

  return (
    <Dialog open={isOpen} onClose={closeCallback}>
      {
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpenBackDrop}>
          <CircularProgress />
        </Backdrop>
      }
      <DialogTitle>配送先の選択</DialogTitle>
      <DialogContent>
        <div className="mb-2 ml-2">ご希望の配送先を選択してください</div>
        <div key={shop.shopId} className="mb-4">
          <p className="text-lg mb-1">{shop.shopName}</p>
          {shop.spots.map((spot) => (
            <div
              key={spot.spotId}
              className={`mt-2 flex gap-2 items-center cursor-pointer p-2 rounded-md ${selectStyle(
                spot.spotId
              )}`}
              onClick={() => {
                if (isSpotEnable(spot.spotId) && !isOpenBackDrop) {
                  changeSpotCallback(spot.spotId);
                }
              }}
              style={{
                pointerEvents: isOpenBackDrop ? 'none' : 'auto',
                opacity: isOpenBackDrop ? 0.5 : 1,
              }}
            >
              {spot.image.length !== 0 ? (
                <img
                  alt="配達場所画像"
                  src={spot.image[0]}
                  className="w-1/2 h-1/3 object-contain rounded-t-md"
                  style={{ filter: isSpotEnable(spot.spotId) ? 'none' : 'grayscale(100%)' }} // 無効な場合グレースケール
                />
              ) : (
                <div className="w-1/2 h-1/3 text-center">画像なし</div>
              )}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {' '}
                <p>{spot.spotName}</p>
                {!isSpotEnable(spot.spotId) && (
                  <p style={{ color: 'red', marginTop: '4px' }}>お届け可能な時間がありません</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeCallback} autoFocus>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
