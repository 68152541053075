/* eslint-disable @typescript-eslint/no-explicit-any */
import { CONST } from '@common/global';
const PAGECON_URL = CONST.API_ENDPOINT + 'v1/sbps-ret'; //購入結果通知URL
const __ORDER_ID__ = '~~~~ORDER_ID~~~~';
const __SBPS_ORDER_ID__ = '~~~~SBPS_ORDER_ID~~~~';

/*
const pay_methods: string[] = [
  'credit', //:クレジットカード決済
  'credit3d', //:クレジットカード決済(本人認証サービス(3-D セキュア))
  'credit3d2', //:クレジットカード決済(本人認証サービス(EMV 3-D セキュア))
  'unionpay', //:銀聯ネット決済
  'paypay', //:PayPay(オンライン決済)
  'webcvs', //:コンビニ決済
  'payeasy', //:Pay-easy 決済
  'banktransfer', //:総合振込決済
  'cyberedy', //:楽天 Edy 決済(楽天 Edy)
  'mobileedy', //:楽天 Edy 決済(モバイル楽天 Edy)
  'suica', //:モバイル Suica 決済
  'webmoney', //:WebMoney 決済
  'netcash', //:Net Cash 決済
  'bitcash', //:BitCash 決済
  'prepaid', //:JCB PREMO 決済
  'docomo', //:ドコモ払い
  'auone', //:au かんたん決済可変
  'softbank', //:S!まとめて支払い
  'yahoowallet2', //:ウォレット決済サービス(Type-Y)
  'yahoowalletdg', // : Yahoo! ウ ォレット決 済(デジ コ ン版)
  'rakuten', //:楽天ペイ(オンライン決済)
  'recruit', //:リクルートかんたん支払い
  'alipay', //:Alipay 国際決済
  'paypal', //:Paypal 決済
  'netmile', //:ネットマイル決済
  'mysoftbank', // : ソ フ ト バ ン ク ま と め て 支 払 い ( A )
  'softbank2', //:ソフトバンクまとめて支払い(B)
  'saisonpoint', //:永久不滅ポイント
  'linepay', //:LINE Pay
  'merpay', //:メルペイネット決済
  'tpoint', //:T ポイントプログラム(オンライン決済)
  'applepay', //:Apple Pay
  'nppostpay', //:NP 後払い
  'aupay', //:au PAY(ネット支払い)
  'eposeasypay', //:エポスかんたん決済
];
*/

class SBSPOrder {
  static Order = class Order {
    static OrderDetail = class OrderDetail {
      dtl_rowno: string;
      dtl_item_id: string;
      dtl_item_name: string;
      dtl_item_count: string;
      dtl_tax: string;
      dtl_amount: string;
      dtl_free1: string;
      dtl_free2: string;
      dtl_free3: string;

      constructor() {
        /**
         * 明細行番号(必須 2桁)
         * @type {string}
         */
        this.dtl_rowno = '';
        /**
         * 明細商品 ID(省略可 20桁)
         * @type {string}
         */
        this.dtl_item_id = '';
        /**
         * 明細商品名称(省略可 40桁)
         * @type {string}
         */
        this.dtl_item_name = '';
        /**
         * 明細数量(省略可 6桁)
         * @type {string}
         */
        this.dtl_item_count = '';
        /**
         * 明細税額(省略可 8桁)
         * @type {string}
         */
        this.dtl_tax = '';
        /**
         * 明細金額(税込)(省略可 8桁)
         * @type {string}
         */
        this.dtl_amount = '';
        /**
         * 明細自由欄 1(省略可 3000桁)
         * @type {string}
         */
        this.dtl_free1 = '';
        /**
         * 明細自由欄 3(省略可 3000桁)
         * @type {string}
         */
        this.dtl_free2 = '';
        /**
         * 明細自由欄 3(省略可 3000桁)
         * @type {string}
         */
        this.dtl_free3 = '';
      }
    };
    /**
     * 支払方法(省略可 1000桁)
     * 支払方法を指定します。(複数指定可)
     * 複数指定時はカンマ区切り
     * 省略時または複数指定時はSBPにて決済選択画面を表示
     * @type {string}
     */
    pay_method: string;
    private merchant_id: string;
    private service_id: string;
    private cust_code: string;
    private sps_cust_no: string;
    private sps_payment_no: string;
    /**
     * 購入 ID(必須 64桁)
     * 常にユニーク重複するとエラーとなる
     * @type {string}
     */
    order_id: string;
    /**
     * 商品 ID(必須 38桁)
     * 加盟店にて管理されている商品 ID
     * @type {string}
     */
    item_id: string;
    private pay_item_id: string;
    /**
     * 商品名称（省略可 40桁）
     * 設定されない場合は、購入内容確認画面において商品名の項目自体が表示されません。
     * @type {string}
     */
    item_name: string;
    /**
     * 税額（省略可 7桁）
     * @type {string}
     */
    tax: string;
    /**
     * 金額(税込)（必須 7桁）
     * @type {string}
     */
    amount: string;
    private pay_type: string;
    private auto_charge_type: string;
    private service_type: string;
    private div_settele: string;
    private last_charge_month: string;
    private camp_type: string;
    private tracking_id: string;
    /**
     * 顧客利用端末タイプ（必須 1桁）
     * 0:PC
     * 1:フィーチャーフォン
     * @type {string}
     */
    terminal_type: string;
    /**
     * 決済完了時 URL（必須 500桁）
     * 画面戻り先 URL
     * @type {string}
     */
    success_url: string;
    /**
     * 決済キャンセル時 URL（必須 500桁）
     * 画面戻り先 URL
     * @type {string}
     */
    cancel_url: string;
    /**
     * エラー時 URL（必須 500桁）
     * 画面戻り先 URL
     * @type {string}
     */
    error_url: string;
    /**
     * 決済通知用 CGI（必須 500桁）
     * 画面戻り先 URL
     * @type {string}
     */
    private pagecon_url: string;
    /**
     * 自由欄1（省略可 20桁）
     * @type {string}
     */
    free1: string;
    /**
     * 自由欄2（省略可 20桁）
     * @type {string}
     */
    free2: string;
    /**
     * 自由欄3（省略可 20桁）
     * @type {string}
     */
    free3: string;
    /**
     * フリー項目（省略可 4000桁）
     * 商品名称や住所情報など支払先によっては必須
     * @type {string}
     */
    free_csv: string;
    /**
     * 明細詳細設定配列
     * @type {Order.OrderDetail 配列}
     */
    orderDetail: Array<any>;
    private request_date: string;
    private limit_second: string;
    private sps_hashcode: string;

    constructor() {
      this.pay_method = '';
      this.merchant_id = '83151';
      this.service_id = '004';
      this.cust_code = '';
      this.sps_cust_no = '';
      this.sps_payment_no = '';
      this.order_id = '';
      this.item_id = '';
      this.pay_item_id = '';
      this.item_name = '';
      this.tax = '';
      this.amount = '';
      this.pay_type = '0';
      this.auto_charge_type = '';
      this.service_type = '0';
      this.div_settele = '';
      this.last_charge_month = '';
      this.camp_type = '';
      this.tracking_id = '';
      this.terminal_type = '';
      this.success_url = '';
      this.cancel_url = '';
      this.error_url = '';
      this.pagecon_url = PAGECON_URL;
      this.free1 = '';
      this.free2 = '';
      this.free3 = '';
      this.free_csv = '';
      this.orderDetail = [];
      this.request_date = '';
      this.limit_second = '';
      this.sps_hashcode = '';
    }
  };
}

function createForm(order: any) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.hidden = true;
  form.acceptCharset = 'Shift_JIS';

  document.body.appendChild(form);
  append_input_hidden(form, 'pay_method', order.pay_method);
  append_input_hidden(form, 'merchant_id', order.merchant_id);
  append_input_hidden(form, 'service_id', order.service_id);
  append_input_hidden(form, 'cust_code', order.cust_code);
  append_input_hidden(form, 'sps_cust_no', order.sps_cust_no);
  append_input_hidden(form, 'sps_payment_no', order.sps_payment_no);
  append_input_hidden(form, 'order_id', order.order_id);
  append_input_hidden(form, 'item_id', order.item_id);
  append_input_hidden(form, 'pay_item_id', order.pay_item_id);
  append_input_hidden(form, 'item_name', order.item_name);
  append_input_hidden(form, 'tax', order.tax);
  append_input_hidden(form, 'amount', order.amount);
  append_input_hidden(form, 'pay_type', order.pay_type);
  append_input_hidden(form, 'auto_charge_type', order.auto_charge_type);
  append_input_hidden(form, 'service_type', order.service_type);
  append_input_hidden(form, 'div_settele', order.div_settele);
  append_input_hidden(form, 'last_charge_month', order.last_charge_month);
  append_input_hidden(form, 'camp_type', order.camp_type);
  append_input_hidden(form, 'tracking_id', order.tracking_id);
  append_input_hidden(form, 'terminal_type', order.terminal_type);
  append_input_hidden(form, 'success_url', order.success_url);
  append_input_hidden(form, 'cancel_url', order.cancel_url);
  append_input_hidden(form, 'error_url', order.error_url);
  append_input_hidden(form, 'pagecon_url', order.pagecon_url);
  append_input_hidden(form, 'free1', order.free1);
  append_input_hidden(form, 'free2', order.free2);
  append_input_hidden(form, 'free3', order.free3);
  append_input_hidden(form, 'free_csv', order.free_csv);
  append_input_hidden(form, 'request_date', order.request_date);
  append_input_hidden(form, 'limit_second', order.limit_second);
  append_input_hidden(form, 'sps_hashcode', order.sps_hashcode);
  for (let i = 0; i < order.orderDetail.length; i++) {
    append_input_hidden(form, 'dtl_rowno', order.orderDetail[i].dtl_rowno);
    append_input_hidden(form, 'dtl_item_id', order.orderDetail[i].dtl_item_id);
    append_input_hidden(form, 'dtl_item_name', order.orderDetail[i].dtl_item_name);
    append_input_hidden(form, 'dtl_item_count', order.orderDetail[i].dtl_item_count);
    append_input_hidden(form, 'dtl_tax', order.orderDetail[i].dtl_tax);
    append_input_hidden(form, 'dtl_amount', order.orderDetail[i].dtl_amount);
    append_input_hidden(form, 'dtl_free1', order.orderDetail[i].dtl_free1);
    append_input_hidden(form, 'dtl_free2', order.orderDetail[i].dtl_free2);
    append_input_hidden(form, 'dtl_free3', order.orderDetail[i].dtl_free3);
  }

  return form;
}

function append_input_hidden(form: HTMLFormElement, name: string, value: string) {
  const q = document.createElement('input');
  q.type = 'hidden';
  q.name = name;
  q.value = value;
  form.append(q);
}

export function getFormValues(form: HTMLFormElement) {
  let formValues = '';
  const inputs = form?.getElementsByTagName('input') as HTMLCollectionOf<HTMLInputElement>;
  const cust_code = 'cust_code';
  const replace_code = '~~~~CUST_CODE~~~~';
  const skipList: string[] = [
    'sps_cust_no',
    'sps_payment_no',
    'request_date',
    'limit_second',
    'sps_hashcode',
    'submit',
  ];
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].name === cust_code) {
      formValues += replace_code;
    } else {
      if (!skipList.includes(inputs[i].name)) {
        formValues += inputs[i].value;
      }
    }
  }
  console.log(formValues);
  return formValues;
}

export function updateFormValues(form: HTMLFormElement, values: { [key: string]: string }) {
  form.cust_code.value = values.cust_code;
  form.sps_cust_no.value = values.sps_cust_no;
  form.sps_payment_no.value = values.sps_payment_no;
  form.request_date.value = values.request_date;
  form.limit_second.value = values.limit_second;
  form.sps_hashcode.value = values.sps_hashcode;
  form.order_id.value = values.sbps_order_id;
  form.success_url.value = form.success_url.value.replace(__ORDER_ID__, values.order_id);
  form.cancel_url.value = form.cancel_url.value.replace(__ORDER_ID__, values.order_id);
  form.error_url.value = form.error_url.value.replace(__ORDER_ID__, values.order_id);
  form.action = values.sbps_post_url;
}

export function BuyRequest(
  items: { name: string; price: number; itemId: string; count: number }[],
  totalPrice: number,
  vendingMachine = false
) {
  const order = new SBSPOrder.Order();
  const topUrl = window.location.protocol + '//' + window.location.host + '/';
  const successPage = 'order/completed/';
  const cancelPage = 'order/canceled/';
  const errorPage = 'order/failed/';
  //order.pay_method = 'credit,docomo,auone,softbank2,paypay'; //スペースを入れないこと
  order.pay_method = 'credit,docomo,softbank2,paypay'; //スペースを入れないこと
  if (vendingMachine) order.pay_method = 'paypay';
  order.order_id = __SBPS_ORDER_ID__;
  order.item_id = 'T_0000'; // TODO
  order.item_name = '購入商品';
  order.tax = '';
  order.amount = String(totalPrice);
  order.terminal_type = '0';
  order.success_url =
    CONST.API_ENDPOINT + 'v1/redirect/?url=' + topUrl + successPage + __ORDER_ID__;
  order.cancel_url = CONST.API_ENDPOINT + 'v1/redirect/?url=' + topUrl + cancelPage + __ORDER_ID__;
  order.error_url = CONST.API_ENDPOINT + 'v1/redirect/?url=' + topUrl + errorPage + __ORDER_ID__;
  order.free1 = '';
  order.free2 = '';
  order.free3 = '';

  items.forEach(
    (item: { name: string; price: number; itemId: string; count: number }, index: number) => {
      const orderDetail = new SBSPOrder.Order.OrderDetail();
      orderDetail.dtl_rowno = String(index + 1);
      orderDetail.dtl_item_id = item.itemId;
      orderDetail.dtl_item_name = item.name;
      orderDetail.dtl_item_count = String(item.count);
      orderDetail.dtl_tax = '';
      orderDetail.dtl_amount = String(item.price); //'1100';
      orderDetail.dtl_free1 = '';
      orderDetail.dtl_free2 = '';
      orderDetail.dtl_free3 = '';
      order.orderDetail.push(orderDetail);
    }
  );
  // フリーCSV
  order.free_csv = '';

  return createForm(order);
}
