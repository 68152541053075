import { atom, selector, useRecoilValue } from 'recoil';
import { api, ApiShop, ApiSpot } from './Api';
import { deliveryToState, spotSelectedIdState } from './OrderDeliveryTo';
import { RecoilKeys } from './RecoilKeys';

// 配達場所情報を付与したtype
export type Shop = ApiShop;

// const shopsState = atom<Shop[]>({
//   key: RecoilKeys.SHOPS_STATE,
//   effects: [
//     ({ setSelf }) => {
//       const initialize = async () => {
//         try {
//           const shops = await api.fetchShops();
//           setSelf(shops);
//         } catch (error) {
//           console.error('fetchShops failed:', error);
//         }
//       };

//       initialize();
//     },
//   ],
// });

const shopsState = atom<Shop[]>({
  key: RecoilKeys.SHOPS_STATE,
  effects: [
    ({ setSelf, getPromise, trigger }) => {
      console.log('shopsState effect');
      if (trigger === 'get') {
        console.log("shopsState trigger is 'get'");
        // 配達場所を取得
        setSelf(api.fetchShops());
      }
    },
  ],
});

export type ShopIncludeSpots = Shop & {
  spots: ApiSpot[];
};

export const shopsIncludeSpots = selector<ShopIncludeSpots[]>({
  key: RecoilKeys.SHOPS_INCLUDE_SPOTS,
  get: ({ get }) => {
    const shops = get(shopsState);
    const spots = get(deliveryToState);
    return shops.map((shop) => {
      const relatedSpots = shop.spotId
        .map((spotId) => spots.find((spot) => spot.spotId === spotId))
        .filter((spot): spot is ApiSpot => spot !== undefined);
      return { ...shop, spots: relatedSpots };
    });
  },
});

export const getShops = selector<Shop[]>({
  key: RecoilKeys.SHOPS_GET_SHOPS,
  get: () => api.fetchShops(),
});

export const shopSelected = selector<ShopIncludeSpots>({
  key: RecoilKeys.SHOP_SELECTED,
  get: ({ get }) => {
    const shops = get(shopsIncludeSpots);
    const spotSelectedId = get(spotSelectedIdState);
    const shopSelected = shops.find((shop) => shop.spotId.includes(spotSelectedId));
    // 選択しているshopがなければ、1つ目を返却
    if (!shopSelected) {
      return shops[0];
    }

    return shopSelected;
  },
});

function useSelectedShopSpots() {
  const shop = useRecoilValue(shopSelected);
  return shop.spots;
}

export const dataShop = {
  useShopsValue: () => useRecoilValue(shopsState),
  useShopSelected: () => useRecoilValue(shopSelected),
  useShopSpotsCount: () => useSelectedShopSpots().length,
  useHasTwoOrMoreSpots: () => useSelectedShopSpots().length >= 2,
};
