import { atom, selector, selectorFamily, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { DeliveryTime } from './OrderDeliveryTime';
import { api } from './Api';
import moment from 'moment';
import NoImage from '../assets/NoImage.svg';

// export type OrderState = "placed" | "accepted" | "onDelivery" | "posted" | "completed" | "cancelled";
export type OrderState =
  | 'paying'
  | 'credited'
  | 'assigned'
  | 'accepted'
  | 'onDelivery'
  | 'arrived'
  | 'posted'
  | 'completed'
  | 'payingTimeout'
  | 'failed'
  | 'cancelled'
  | 'waitingCustomerByStockOut';

export type OrderItem = {
  itemId: string;
  count: number;
  price: number;
  name: string;
  maker: string;
  image: string;
};

export type OrderItemState = {
  orderId: string;
  state: OrderState;

  items: OrderItem[];
  deliveryFee: number;
  discount: number;
  totalPrice: number;
  totalCount: number;
  merchandisePrice: number;

  deliveryTime?: DeliveryTime;
  deliveryTo: string;
  deliverySpotId: string;

  timePaying: Date;
  timeCredited?: Date;
  timeAccepted?: Date;
  timeOnDelivery?: Date;
  timeArrived?: Date;
  timePosted?: Date;
  timeCompleted?: Date;
  timePayingTimeout?: Date;
  timeFailed?: Date;
  timeCancelled?: Date;

  boxId?: string;
  cancelCause?: string;
  stockOutItems?: OrderItem[];

  receiptNumber?: string;
  boxKey?: string;

  payMethod?: string;
  phoneNumber?: string;
};

const orderState = atom<OrderItemState[]>({
  key: RecoilKeys.ORDER_STATE,

  // TODO: order_appではコメントアウトされていた
  effects: [
    ({ setSelf, onSet, trigger }) => {
      console.log('orderState effect');
      if (trigger === 'get') {
        console.log("orderState trigger is 'get'");

        const initialize = () => {
          console.log('orderState initialize');
          const getOrders = async () => {
            const apiOrders = await api.ListOrders();
            const convDeliveryTime = (begin: number, end: number): DeliveryTime | undefined => {
              if (begin === 0) {
                return undefined;
              }
              const beginM = moment.unix(begin).utcOffset(+9);
              const endM = moment(moment.unix(end).utcOffset(+9));
              return new DeliveryTime(
                beginM.year(),
                beginM.month(),
                beginM.date(),
                beginM.hour(),
                beginM.minute(),
                endM.hour(),
                endM.minute()
              );
            };
            const convTimestamp = (tp: number | undefined): Date | undefined =>
              tp != null ? moment.unix(tp).utcOffset(+9).toDate() : undefined;
            const paymentType = (type: string): string => {
              switch (type) {
                case 'docomo':
                  return 'd払い';
                case 'credit':
                  return 'クレジットカード';
                case 'softbank2':
                  return 'ソフトバンクまとめて支払い／ワイモバイルまとめて支払い';
                case 'paypay':
                  return 'PayPay';
                default:
                  return 'その他';
              }
            };

            return apiOrders.map((apiOrder) => {
              const order: OrderItemState = {
                orderId: apiOrder.orderId,
                timePaying: convTimestamp(apiOrder.timePaying) as Date,
                timeCredited: convTimestamp(apiOrder.timeCredited),
                timeAccepted: convTimestamp(apiOrder.timeAccepted),
                timeOnDelivery: convTimestamp(apiOrder.timeOnDelivery),
                timePosted: convTimestamp(apiOrder.timePosted),
                timeArrived: convTimestamp(apiOrder.timeArrived),
                timeCompleted: convTimestamp(apiOrder.timeCompleted),
                timePayingTimeout: convTimestamp(apiOrder.timePayingTimeout),
                timeFailed: convTimestamp(apiOrder.timeFailed),
                timeCancelled: convTimestamp(apiOrder.timeCancelled),
                boxId: apiOrder.boxId,
                cancelCause: apiOrder.cancelCause,
                state: apiOrder.state as OrderState,
                items: apiOrder.items.map((i) => {
                  return {
                    itemId: i.itemId,
                    count: i.count,
                    price: i.price,
                    name: i.name,
                    maker: i.maker,
                    image: i.image ? i.image.split(';')[0] : NoImage,
                  };
                }),
                deliveryFee: apiOrder.deliveryFee,
                discount: apiOrder.discount ?? 0,
                totalPrice: apiOrder.price,
                merchandisePrice:
                  apiOrder.price - Number(apiOrder.deliveryFee) - Number(apiOrder.discount),
                totalCount: apiOrder.items.reduce((sum, i) => sum + i.count, 0),
                deliveryTime: convDeliveryTime(
                  apiOrder.deliveryTimeBegin,
                  apiOrder.deliveryTimeEnd
                ),
                stockOutItems: apiOrder.stockOutItems,
                deliveryTo: apiOrder.deliveryTo,
                deliverySpotId: apiOrder.deliverySpotId,
                receiptNumber: apiOrder.receiptNumber ?? '',
                boxKey: apiOrder.boxKey ?? '',
                payMethod: paymentType(apiOrder.payMethod ?? ''),
                phoneNumber: apiOrder.phoneNumber ?? '',
              };
              return order;
            });
          };
          getOrders().then((orders) => {
            // console.log(orders.length);
            setSelf(orders);
          });
        };
        initialize();
        setInterval(initialize, 10000);
      }

      onSet((newState) => {});
    },
  ],
});

const orderItemSelector = selectorFamily({
  key: RecoilKeys.ORDER_ITEM,
  get:
    (orderId) =>
    ({ get }) => {
      const items = get(orderState);
      return items.find((item) => item.orderId === orderId);
    },
});

const orderItemsOnDeliverySelector = selector({
  key: RecoilKeys.ORDER_ITEMS_ON_DELIVERY,
  get: ({ get }) => {
    return get(orderState)
      .filter(
        (order) =>
          order.state === 'paying' ||
          order.state === 'credited' ||
          order.state === 'assigned' ||
          order.state === 'accepted' ||
          order.state === 'onDelivery' ||
          order.state === 'arrived' ||
          order.state === 'posted' ||
          order.state === 'waitingCustomerByStockOut'
      )
      .sort((a, b) => {
        if (a.timePaying < b.timePaying) {
          return 1;
        } else if (a.timePaying > b.timePaying) {
          return -1;
        }
        return 0;
      });
  },
});

const orderCountOnDeliverySelector = selector({
  key: RecoilKeys.ORDER_COUNT_ON_DELIVERY,
  get: ({ get }) => {
    return get(orderItemsOnDeliverySelector).length;
  },
});

// export
export const dataOrderHistory = {
  useOrderHistory: () => useRecoilValue(orderState),
  useOrderItem: (orderId: string) => useRecoilValue(orderItemSelector(orderId)),
  useOrderItemsOnDelivery: () => useRecoilValue(orderItemsOnDeliverySelector),
  useOrderCountOnDelivery: () => useRecoilValue(orderCountOnDeliverySelector),
};
