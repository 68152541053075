import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { RecoilKeys } from './RecoilKeys';

export const OrderPhoneNumberState = atom<string>({
  key: RecoilKeys.ORDER_PHONE_NUMBER,
  default: '',
});

export const OrderPhoneNumberNoHyphen = selector<string>({
  key: RecoilKeys.ORDER_PHONE_NUMBER_NO_HYPHEN,
  get: ({ get }) => {
    const phoneNumber = get(OrderPhoneNumberState);
    return phoneNumber.replace(/-/g, '');
  },
});

export const dataOrderPhoneNumber = {
  useOrderPhoneNumberState: () => useRecoilValue(OrderPhoneNumberState),
  useOrderPhoneNumberNoHyphen: () => useRecoilValue(OrderPhoneNumberNoHyphen),
  useSetOrderPhoneNumber: () =>
    useRecoilCallback(
      ({ set }) =>
        (value: string) =>
          set(OrderPhoneNumberState, value)
    ),
};
