import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
  // interface Palette {
  //   badge: Palette['primary'];
  // }
  // interface PaletteOptions {
  //   badge: PaletteOptions['primary'];
  // }

  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }

  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    // tailwind.config.js と合わせること
    primary: {
      main: '#fbaa0d', // public/index.html と manifest.json のtheme-colorも合わせること
    },
    secondary: {
      main: '#2196f3',
    },
    warning: {
      main: '#f50057',
    },
    text: {
      primary: '#3E2723',
    },
  },
});
