import {
  selector,
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';

// 同名のメーカーがあったときのために id を定義しておく（未使用）
export type ShopItemMaker = {
  readonly makerId: string;
  readonly makerName: string;
  readonly image?: string;
  readonly businessHours?: string;
  readonly holiday?: string;
  readonly postalCode?: string;
  readonly address?: string;
  readonly phone?: string;
  readonly url?: string;
  readonly latitude?: string;
  readonly longitude?: string;
  readonly description?: string;
};

const shopItemsMakerAll = selector<ShopItemMaker[]>({
  key: RecoilKeys.SHOP_ITEM_MASTER_ALL,
  get: ({ get }) => api.fetchMakers(),
});

// const shopItemMakerByName = selectorFamily<ShopItemMaker | undefined, string>({
//   key: RecoilKeys.SHOP_ITEM_MAKER_BY_NAME,
//   get: (makerName) => ({get}) => {
//     return get(shopItemsMakerAll).find((maker) => maker.makerName === makerName);
//   },
// });

export const shopItemMaker = selectorFamily<ShopItemMaker | undefined, string>({
  key: RecoilKeys.SHOP_ITEM_MAKER,
  get:
    (makerId) =>
    ({ get }) => {
      return get(shopItemsMakerAll).find((maker) => maker.makerId === makerId);
    },
});

// export
export const dataShopItemMaker = {
  // useShopItemMakerByName: (makerName: string) => useRecoilValue(shopItemMakerByName(makerName)),
  useAllShopItemMakerLoadable: () => useRecoilValueLoadable(shopItemsMakerAll),
  useShopItemMakerRefresher: () => useRecoilRefresher_UNSTABLE(shopItemsMakerAll),
  useShopItemMaker: (makerName: string) => useRecoilValue(shopItemMaker(makerName)),
};
