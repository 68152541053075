import { useEffect } from 'react';

export const useOnline = (onlineCallback: () => void, offlineCallback: () => void) => {
  useEffect(() => {
    window.addEventListener('online', onlineCallback);
    window.addEventListener('offline', offlineCallback);

    return () => {
      window.removeEventListener('online', onlineCallback);
      window.removeEventListener('offline', offlineCallback);
    };
  }, []);
};
